<template>
  <section id="intro">
    <div class="center">
      <Logo/>
      <br>
      <h4>IT'LL ALL MAKE SENSE IN THE END</h4>
      <br>
      <p>Navigate your way through the maze for the chance of winning 2 VIP tickets to a James Arthur headline live show in 2022 of your choice... and more.</p>
      <br>
      <br>
      <Button v-on:click="onClick">Enter the maze</Button>
      <br>
      <br> 
    </div>
    <AlbumButton/>
  </section>
</template>
<script>
  import Logo from './Logo.vue';
  import Button from './Button.vue';
  import AlbumButton from './AlbumButton.vue';
  export default {
    name: 'Intro',
    components: {
      Logo,
      Button,
      AlbumButton,
    },
    methods: {

      onClick: function( ) {
        this.$router.push({ name: 'maze' });
      }
      
    }
  }
</script>