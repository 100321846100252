<template>
  <div class="landscape-overlay" :class="{show:show}">
    <h4 class="gold">This experience works best in portrait mode</h4>
    <h2 class="grey">Please rotate your device</h2>
  </div>
</template>
<script>
  export default {

    data: () => ({
      show: false,
    }),

    mounted(){
      window.addEventListener("orientationchange", this.onChange, false);
      this.onChange();
    },

    methods: {
      onChange(){
        // https://stackoverflow.com/questions/66836579/how-to-get-current-screen-orientation-in-ios-safari
        let orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
        this.show = ( orientation && ( orientation === "landscape-primary" || orientation === "landscape-secondary" || orientation === "portrait-secondary" ) ) ? true : false;
      }
    }

  }
</script>
