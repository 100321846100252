import {
  Group,
  Vector3,
  HemisphereLight,
  AmbientLight,
  PointLight,
} from "three"; //https://threejsfundamentals.org/threejs/lessons/threejs-lights.html

import { Lensflare, LensflareElement } from './Lensflare.js'; // https://threejs.org/examples/#webgl_lensflares

class Lights {

  constructor( maze ) {

    const CONFIG = window.CONFIG || null;

    const lights = new Group();
    let endContainer = new Vector3();
    endContainer.copy(maze.end);

    const hemispherelight = new HemisphereLight(0xFBF7DD, 0x090B0B, 0.5); // sky, ground, intensity
    if(CONFIG.debug) hemispherelight.intensity = 0.75; // FOR DEBUG!!
    lights.add( hemispherelight );

    const ambientlight = new AmbientLight(0x758A9B, 0.25); // global color, intensity
    if(CONFIG.debug) ambientlight.intensity = 0.5; // FOR DEBUG!!
    lights.add( ambientlight );

    if( CONFIG.debug != true){

      /* END LIGHTS */
      const  endPointLight = new PointLight(0xF4722F, 300, 64, 2);
      endPointLight.position.set(endContainer.x,endContainer.y,endContainer.z);
      // pointLight.rotation.z = MathUtils.degToRad(-45);
      lights.add(endPointLight);

      const  endPointLight2 = new PointLight(0xF4722F, 150, 32, 2);
      endPointLight2.position.set(endContainer.x-14,endContainer.y,endContainer.z);
      lights.add(endPointLight2);

      const light = new PointLight( 0xffffff, 1.5, 50 );
      light.color.setHSL( 0.995, 0.5, 0.9 );
      light.position.set( endContainer.x+14,endContainer.y+1.8, endContainer.z+3.6);
      lights.add( light );

      const lensflare = new Lensflare();
      lensflare.addElement( new LensflareElement( CONFIG.textures.find(obj => { return obj.name === 'lens0' }).material.map, 350, 0, light.color ) );
      lensflare.addElement( new LensflareElement( CONFIG.textures.find(obj => { return obj.name === 'lens3' }).material.map, 60, 0.6 ) );
      lensflare.addElement( new LensflareElement( CONFIG.textures.find(obj => { return obj.name === 'lens3' }).material.map, 70, 0.7 ) );
      lensflare.addElement( new LensflareElement( CONFIG.textures.find(obj => { return obj.name === 'lens3' }).material.map, 120, 0.9 ) );
      lensflare.addElement( new LensflareElement( CONFIG.textures.find(obj => { return obj.name === 'lens3' }).material.map, 70, 1 ) );
      light.add( lensflare );

    }

    this.update = function(){
     // pointLightHelper.update();
    }

    this.get = function(){
      return lights;
    }

    return this;
  }
}
export { Lights }