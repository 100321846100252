<template>
  <div class="checkbox"> 
    <label :for="label">
      <input v-on:click="$emit('click')" type="checkbox" :id="label">
      <span class="box"></span>
    </label>
    <p class="small left"><slot/></p>
  </div>
</template>
<script>
  export default {
    name: 'Checkbox',
    props: {
      label:{
        default: null,
        type: String || null
      },
    }
  }
</script>