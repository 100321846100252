<template>
  <footer class="global-footer">
    <span class="global-footer__copyright">&copy; 2021 Sony Music Entertainment UK LTD</span>
    <ul class="footer-nav" :class="{ 'footer-nav--active': showMenu }">
      <li class="footer-nav__item">
        <a
          href="https://tools.sonymusiccreative.com/privacy/"
          rel="noreferrer noopener"
          target="_blank"
          class="footer-nav__link"
          >Privacy & Cookie Policy</a>
      </li>
      <li class="footer-nav__item">
        <a
          href="./terms-and-conditions.pdf"
          rel="noreferrer noopener"
          target="_blank"
          class="footer-nav__link"
          >Terms and Conditions</a
        >
      </li>
    </ul>

    <span
      class="global-footer__toggle"
      :class="{ 'global-footer__toggle--active': showMenu }"
      @click="toggleFooter"
      :aria-label="!showMenu ? 'Open Menu' : 'Close Menu'"
      role="button"
    ></span>
  </footer>
</template>
<script>
	export default {
		name: 'Footer',
		data() {
			return {
				showMenu: false,
			};
		},
    methods: {
      toggleFooter(){
        this.showMenu = !this.showMenu;
        // setTimeout(() => {
        //   window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        // }, 500) 
      },
    },
	};
</script>