import {
  Raycaster,
  Vector3,
} from "three";

// import { PointerLockControls } from './jsm/controls/PointerLockControls.js';
// import { PointerLockControls } from 'https://unpkg.com/three@0.118.3/examples/jsm/controls/PointerLockControls.js';
// import { PointerLockControls } from 'https://cdn.skypack.dev/three/examples/jsm/controls/PointerLockControls.js';
// import { DeviceOrientationControls } from 'https://cdn.skypack.dev/three/examples/jsm/controls/DeviceOrientationControls.js';

import { DeviceOrientationControls } from './DeviceOrientationControls.js'; /* CUSTOM VERSION */
import { PointerLockControls } from './PointerLockControls.js'; /* CUSTOM VERSION */

class Controls {

  constructor(camera, mouseControls = true, touchEvents = false, deviceOrientation = false) {

    const CONFIG = window.CONFIG || null;

    const raycaster = new Raycaster( new Vector3(), new Vector3( 0, -1, 0 ), 0, 10 ); // pointing down - but we change this later

    this.system; // The actual controls engine (mobile vs desktop)

    this.move  = {
      forward : false,
      backward : false,
      left : false,
      right : false,
      up: false,
      down: false,
      jump : false,
    }

    const speed = ( CONFIG.debug ) ? 10 : 20; // fast : slow
    const mass = ( CONFIG.debug ) ? 50 : 75; // light : heavy
    const jump = ( CONFIG.debug ) ? 200 : 100 // high : low


    let oldCamera, directionCamera, direction, velocity;

    oldCamera = null;
    directionCamera = new Vector3();
    direction = new Vector3();
    velocity = new Vector3();
    
    if( mouseControls ){ // IF DESKTOP AND MOUSE
      this.system = new PointerLockControls( camera, document.body ); 
      // scene.add( controls.getObject() );
      // const onLock = function ( event ) {
      //   console.log( 'PAUSE!!!!!', this.paused )
      //   this.paused = ( event.type != 'lock' ) ? true : false;
      // };
      // this.system.addEventListener( 'lock', onLock, false);
      // this.system.addEventListener( 'unlock', onLock, false);
    }else if( deviceOrientation ){ // IF MOBILE AND HTTPS
      camera.rotateX = 90;
      this.system = new DeviceOrientationControls( camera, document.body );  // local custom version
      // controls = new DeviceOrientationControls( camera ); 
    }else {
      console.error('NO CONTROL SYSTEM AVAILABLE');
      return null;
    }
  
    const onTouch =  ( event ) =>{
      if( !this.system.isLocked && event.type == 'touch') this.system.lock();
      if( !this.system.isLocked ) return;
      this.move.forward = ( event.type == 'touchstart' ) ? true : false; // move forward on tap!
    };
  
    const onKey = ( event ) =>{

      if( !this.system.isLocked ) return;
  
      event.preventDefault();

      if( CONFIG.free ){ /* FREE CONTROLS */ 

        switch ( event.code ) {
          
          case 'KeyW':
            camera.translateY( 1 );
            break;
          case 'KeyA':
            camera.translateY( -1 );
            break;
          case 'KeyS':
            camera.translateX( -1 );
            break;
          case 'KeyD':
            camera.translateX( 1 );
            break;
          case 'ArrowUp':
            camera.translateY( 10 );
            break;
          case 'ArrowDown':
            camera.translateY( -10 );
            break;
          case 'ArrowLeft':
            camera.translateX( -10 );
            break;
          case 'ArrowRight':
            camera.translateX( 10 );
            break;
        }

      }else{ /* NORMAL CONTROLS */ 

        switch ( event.code ) {
  
          case 'KeyW':
            this.move.forward = ( event.type == 'keydown' ) ? true : false;
            break;
          case 'KeyA':
            this.move.left  = ( event.type == 'keydown' ) ? true : false;
            break;
          case 'KeyS':
            this.move.backward  = ( event.type == 'keydown' ) ? true : false;
            break;
          case 'KeyD':
            this.move.right  = ( event.type == 'keydown' ) ? true : false;
            break;
          case 'Space':// && event.type == 'keydown':
            if( event.type == 'keydown' && CONFIG.debug ){
              if ( this.move.jump === false ) velocity.y += jump;
              this.move.jump = true;
            }
            break;
        }
      }

    };
  
    if( touchEvents ){
      document.addEventListener("touchstart", onTouch, false);
      document.addEventListener("touchend", onTouch, false);
    }else{
      document.addEventListener( 'keydown', onKey );
      document.addEventListener( 'keyup', onKey );
    }

    let onObject, insideObject, intersections;

    this.update = function ( delta, objects ) {

      if( deviceOrientation && this.system.update ) this.system.update();
      
      if( this.system.isLocked !== true ) return null;

      // MOVE/FALL EASING
      velocity.x -= velocity.x * speed * delta;
      velocity.z -= velocity.z * speed * delta;
      if(!CONFIG.free) velocity.y -= 9.8 * mass * delta; // 100.0 = mass 
      
      // DIRECTION
      direction.z = Number( this.move.forward  ) - Number( this.move.backward );
      direction.x = Number( this.move.right ) - Number( this.move.left );
      direction.normalize(); // this ensures consistent movements in all directions

    
      // ON OBJECT COLLISION
      onObject = false;
      if( objects.walls ){
        raycaster.set( this.system.getObject().position, new Vector3( 0, -1, 0 ) ) // LOOKING DOWN FROM CAMERA
        intersections = raycaster.intersectObjects( objects.walls.children, false );
        onObject = intersections.length > 0;
      }

      // WORK OUT MOVEMENT AMOUNT
      if ( this.move.forward  || this.move.backward ) velocity.z -= direction.z * 400.0 * delta;
      if ( this.move.left || this.move.right ) velocity.x -= direction.x * 400.0 * delta;

      if ( onObject === true && !CONFIG.free ) {
        velocity.y = Math.max( 0, velocity.y );
        this.move.jump = false;
      }
  
      // MOVE CAMERA
      oldCamera = this.system.getObject().clone(); // SAVE CAMERA BEFORE MOVE
      this.system.moveRight( - velocity.x * delta );
      this.system.moveForward( - velocity.z * delta );

      this.system.getObject().position.y += ( velocity.y * delta ); // new behavior not in controls system

      // HIT OBJECT COLLISION
      directionCamera.subVectors( camera.position, oldCamera.position).normalize();
      raycaster.set( camera.position, directionCamera );
      intersections = raycaster.intersectObjects(objects.walls.children, false);

      // console.log( oldCamera.rotation );

      // if( oldCamera.rotation.x < 0  ){
      //   const vector = new Vector3( 0, 0, - 1 );
      //   vector.applyQuaternion( oldCamera.quaternion );
      //   console.log( 'LOOKING DOWN', oldCamera.rotation.x, vector.y );
      // }

      if ( intersections.length > 0 && !CONFIG.free ) { // RESET MOVE IF CAMERA HITS OBJECT

        insideObject = ( 
          intersections[0] &&
          intersections[0].face.materialIndex < 2 &&
          intersections[0].object.material[intersections[0].face.materialIndex] && 
          intersections[0].object.material[intersections[0].face.materialIndex].visible == false
        ) ? true : false;

        if( !insideObject ) {
          this.system.getObject().position.x = oldCamera.position.x;
          this.system.getObject().position.z = oldCamera.position.z;
        }
      }

      // REMAIN ON THE FLOOR
      if ( this.system.getObject().position.y < 10 ) { 
        velocity.y = 0;
        this.move.jump = false;
        this.system.getObject().position.y = 10;
      }
  
    }

    this.reset = function(){
      console.log('RESET IF INSIDE WALL');
      this.system.getObject().position.x = oldCamera.position.x;
      this.system.getObject().position.z = oldCamera.position.z;
    }

    return this;
  }

}

export { Controls };