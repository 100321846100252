class Sounds {

  constructor(  ) {

    const CONFIG = window.CONFIG || null;

    let muted = false;

    const sounds = CONFIG.sounds; /* loaded prior to game */ 
    
    this.toggleMuted = function(audio){
      muted = !audio;
    }


    this.play = function( name ){
      const sound  = sounds.find(obj => { return obj.name === name });
      if( CONFIG.debug || !sound || !sound.audio || sound.audio.paused || muted || !sound.audio.muted || sound.playcount < 1 ) return null;
      if(sound.playcount){
        sound.audio.currentTime = 0;
      }
      sound.audio.muted = false;
      //console.log('play', sound.name, sound.audio.muted, sound.playcount, sound.audio.currentTime);
      if( sound.playcount ) {
        sound.playcount -= 1;
        sound.audio.loop = false;
      }
    }

    this.pause = function( name ){
      const sound  = sounds.find(obj => { return obj.name === name });
      if( CONFIG.debug || !sound || !sound.audio || sound.audio.paused || muted || sound.audio.muted ) return null;
      sound.audio.muted = true;
    }

    this.start = function( name, startMuted = false ){
      const sound  = sounds.find(obj => { return obj.name === name });
      // console.log( sound );
      if( CONFIG.debug || !sound || !sound.audio || !sound.audio.paused || muted || sound.playcount < 1 ) return null;
      // sound.audio.currentTime = 60*4-10;
      if( sound.playcount && !sound.loop ) sound.playcount -= 1;
      sound.audio.play();
      sound.audio.muted = startMuted
      // console.log('start', sound.name, sound.audio.muted);
    }

    this.stop = function( name, reset = false ){
      const sound = sounds.find(obj => { return obj.name === name });
      // console.log( sound );
      if( CONFIG.debug || !sound|| !sound.audio || sound.audio.paused || muted ) return null;
      sound.audio.pause();
      if( reset ) sound.audio.currentTime = 0;
    }

    this.stopAll = function(){
      sounds.forEach( sound => {
        if( sound.audio.paused == false ) sound.audio.pause();
      });
    }

    this.pauseAll = function(){
      sounds.forEach( sound => {
        sound.audio.muted = true;
      });
    }

    return this;
  }

}

export { Sounds };