<template>
  <article id="app" :class="'app--' + $route.name">
    <Share v-if="$route.name != 'maze'"/>
    <router-view/>
    <Footer v-if="!isPlaying" />
    <LandscapeOverlay />
  </article>
</template>
<script>
  import Share from './components/Share.vue';
  import Footer from './components/Footer.vue';
  import LandscapeOverlay from './components/LandscapeOverlay.vue';
  export default {
    name: 'App',
    components: {
      Share,
      Footer,
      LandscapeOverlay,
    },
    
    data: () => ({
      isPlaying : false
    }),

    created: function () {
      this.$root.$on('isPlaying', this.onPlaying);
      // this.$root.$on('isEnded', this.onEnded);
      addEventListener('resize', this.onResize);
      this.onResize();
      document.body.classList.add('page--' + this.$route.name);

      if( this.$route.name != 'outro' ) { // let user re-auth if playing again (but not on outro)
        localStorage.removeItem('Authentication');
      }
    },


    watch: {
      '$route' (to, from) {
        if(from){
          document.body.classList.remove('page--' + from.name);
        }
        document.body.classList.add('page--' + to.name);
      }
    },
    
    methods: {

      onResize() {
        const windowHeight = window.innerHeight;
        document.documentElement.style.setProperty('--viewport-height', windowHeight + 'px');
      },

      onPlaying: function( playing ) {
        this.isPlaying = playing;
      },
    }
  }
</script>
<style lang="scss">
    @import "./assets/scss/styles";
</style>