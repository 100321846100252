<template>
  <section id="maze" ref="wrap">


    <!-- :class="{ fadeHalf : loaded && !isPlaying,  }" --> 


    <transition name="appear">
      <Button className="button-pause" v-if="started && isPlaying && !ended" v-on:click="onClickPause">
        <img src="~@/assets/img/icons/pause.svg" alt="||">
        <span>Pause</span>
      </Button>
    </transition>
    

    <transition name="appear">

      <div class="inner" v-show="!isPlaying && !ended" >

        <div class="center">

          <h4 class="gold">IAM SITE #10{{level}}</h4>
          <!-- <h2 v-if="ended" class="grey">CONGRATULATIONS</h2> -->
          <h2 v-if="!started && !ended" class="grey">HOW TO PLAY</h2>
          <h2 v-else-if="started && !ended" class="grey">PAUSED</h2>
          <br>
          <!-- <Button v-if="ended" v-on:click="onClickEnd">End game</Button> -->
          <Button v-if="!started" v-on:click="onClick">Start game</Button>
          <Button v-else-if="started" v-on:click="onClick">Resume</Button>
          <br>
          <img alt="controls desktop" src="~@/assets/img/other/controls-desktop.png"/>
          <img alt="controls mobile" src="~@/assets/img/other/controls-mobile.png"/>
          <br>
          <Toggle :label="'Audio'" :unchecked="'ON'" :checked="'OFF'" v-on:click="onToggle"></Toggle>
        </div>

      </div>
      
    </transition>

    <transition name="appear">
      
      <div class="inner" :class="{delay : ended}" v-show="!isPlaying && (!loaded || ended )">

        <div v-if="!loaded" class="center">
          <h2 class="grey">LOADING...</h2>
        </div>

      </div>

    </transition>

    <!-- <transition name="appear">
      <div class="inner" v-show="!isPlaying && loaded && ended"></div>
    </transition> -->
    
  </section>
</template>
<script>

  import { Game } from '../assets/js/game.js';
  import { loadTextures, loadSounds, loadImages } from "../assets/js/inc/functions.js";
  import Toggle from './Toggle.vue';
  import Button from './Button.vue';

  export default {
    
    name: 'Maze',
    components: {
      Toggle,
      Button,
    },

    data: () => ({
      loaded: false,
      started: false,
      ended: false,
      audio: true,
      game : false,
    }),

    computed: {

      isPlaying() {
        return ( this.game ) ? this.game.controls.isLocked : false;
      },

      level(){
        // if( this.game && this.game.getLevel ) return this.game.getLevel();
        return ( this.game ) ? this.game.getLevel() :  parseInt(this.$route.params.level) || 0;
      },

      debug(){
        const boo = this.$route.params.debug;
        return ( boo == true || boo == 'true' ) ? true : false;
      },

      free(){
        const boo = this.$route.params.free;
        return ( boo == true || boo == 'true' ) ? true : false;
      }
    },

    watch:{

      isPlaying(){
        if( this.game.getEnded() === true ) this.ended = true;

        if( this.isPlaying ) {
          this.toggleBodyClass('addClass', 'no-select');
        }else{
          this.toggleBodyClass('moveClass', 'no-select');
        }

        this.$root.$emit('isPlaying', this.isPlaying);
      },

      ended(){
        if(this.audio)this.$root.$emit('isEnded', true);
        setTimeout(() => {
          this.$router.push({ name: 'outro' });
        }, 5250);
      }
    },

    async mounted (){
      const time = performance.now();
      // console.log( this.$route, this.level, this.debug, this.god );
      const assets = await this.loadAssets();
      if( this.debug ) console.log( assets );
      this.game = new Game( assets, this.level, this.debug, this.free );
      this.$refs.wrap.prepend( this.game.get() );
      this.game.start();

      setTimeout(() => {
        const then = performance.now();
        if( this.debug ) console.log( 'LOADING TIME', then - time );
        this.loaded = true;
      }, 250);
      
    },

    methods: { 

      async loadAssets() { 
        const assets = [

          { type : 'image', name: 'map-container', file: require( '@/assets/img/game/map-container.png' ) },
          { type : 'image', name: 'map-container-end', file: require( '@/assets/img/game/map-container-end.png' ) },

          { type : 'texture', name: 'logo-avalanche', file: require( '@/assets/img/game/logo-avalanche.png' ), transparent: true },
          { type : 'texture', name: 'logo-emily', file: require( '@/assets/img/game/logo-emily.png' ), transparent: true },
          { type : 'texture', name: 'logo-medicine', file: require( '@/assets/img/game/logo-medicine.png' ), transparent: true },
          { type : 'texture', name: 'logo-september', file: require( '@/assets/img/game/logo-september.png' ), transparent: true },
          { type : 'texture', name: 'logo-sos', file: require( '@/assets/img/game/logo-sos.png' ), transparent: true },
          { type : 'texture', name: 'exit-end', file: require( '@/assets/img/game/exit-end.jpg' ), basic : true },
          { type : 'texture', name: 'exit-side', file: require( '@/assets/img/game/exit-side.jpg' ) },
          { type : 'texture', name: 'exit-side-left', file: require( '@/assets/img/game/exit-side-left.png' ),  transparent: true},
          { type : 'texture', name: 'exit-side-right', file: require( '@/assets/img/game/exit-side-right.png' ), transparent: true },
          { type : 'texture', name: 'exit-top', file: require( '@/assets/img/game/exit-top.jpg' ) },
          { type : 'texture', name: 'entrance-end', file: require( '@/assets/img/game/entrance-end.jpg' ) },
          { type : 'texture', name: 'entrance-side', file: require( '@/assets/img/game/entrance-side.jpg' ) },
          { type : 'texture', name: 'entrance-side-left', file: require( '@/assets/img/game/entrance-side-left.png' ),  transparent: true },
          { type : 'texture', name: 'entrance-side-right', file: require( '@/assets/img/game/entrance-side-right.png' ),  transparent: true },
          { type : 'texture', name: 'entrance-top', file: require( '@/assets/img/game/entrance-top.jpg' ) },
          { type : 'texture', name: 'white-door', file: require( '@/assets/img/game/white-door.jpg' ) },
          { type : 'texture', name: 'white-end', file: require( '@/assets/img/game/white-end.jpg' ) },
          { type : 'texture', name: 'white-side', file: require( '@/assets/img/game/white-side.jpg' ) },
          { type : 'texture', name: 'white-top', file: require( '@/assets/img/game/white-top.jpg' ) },
          { type : 'texture', name: 'yellow-door', file: require( '@/assets/img/game/yellow-door.jpg' ) },
          { type : 'texture', name: 'yellow-end', file: require( '@/assets/img/game/yellow-end.jpg' ) },
          { type : 'texture', name: 'yellow-side', file: require( '@/assets/img/game/yellow-side.jpg' ) },
          { type : 'texture', name: 'yellow-top', file: require( '@/assets/img/game/yellow-top.jpg' ) },
          { type : 'texture', name: 'red-door', file: require( '@/assets/img/game/red-door.jpg' ) },
          { type : 'texture', name: 'red-end', file: require( '@/assets/img/game/red-end.jpg' ) },
          { type : 'texture', name: 'red-side', file: require( '@/assets/img/game/red-side.jpg' ) },
          { type : 'texture', name: 'red-top', file: require( '@/assets/img/game/red-top.jpg' ) },
          { type : 'texture', name: 'green-door', file: require( '@/assets/img/game/green-door.jpg' ) },
          { type : 'texture', name: 'green-end', file: require( '@/assets/img/game/green-end.jpg' ) },
          { type : 'texture', name: 'green-side', file: require( '@/assets/img/game/green-side.jpg' ) },
          { type : 'texture', name: 'green-top', file: require( '@/assets/img/game/green-top.jpg' ) },
          { type : 'texture', name: 'blue-door', file: require( '@/assets/img/game/blue-door.jpg' ) },
          { type : 'texture', name: 'blue-end', file: require( '@/assets/img/game/blue-end.jpg' ) },
          { type : 'texture', name: 'blue-side', file: require( '@/assets/img/game/blue-side.jpg' ) },
          { type : 'texture', name: 'blue-top', file: require( '@/assets/img/game/blue-top.jpg' ) },
          { type : 'texture', name: 'clipboard-1', file: require( '@/assets/img/game/clipboard-back-1.png' ), transparent: true },
          { type : 'texture', name: 'clipboard-2', file: require( '@/assets/img/game/clipboard-back-2.png' ), transparent: true },
          { type : 'texture', name: 'clipboard-3', file: require( '@/assets/img/game/clipboard-back-3.png' ), transparent: true },
          { type : 'texture', name: 'clipboard-4', file: require( '@/assets/img/game/clipboard-back-4.png' ), transparent: true },
          { type : 'texture', name: 'clipboard-5', file: require( '@/assets/img/game/clipboard-back-5.png' ), transparent: true },
          { type : 'texture', name: 'clipboard-top', file: require(  '@/assets/img/game/clipboard-top.png' ), transparent: true },
          { type : 'texture', name: 'background', file: require('@/assets/img/game/background.jpg')},
          { type : 'texture', name: 'ground', file: require('@/assets/img/game/ground.jpg') },

          { type : 'texture', name: 'lens0', file: require('@/assets/img/game/lensflare0.png'), basic : true },
          { type : 'texture', name: 'lens3', file: require('@/assets/img/game/lensflare3.png'), basic : true },

          { type : 'sound', name: 'background', file: require("@/assets/media/00-ambient-shipping-container.mp3"), loop: true, volume: 0.5, audio : null }, // DONE
          { type : 'sound', name: 'walking', file: require("@/assets/media/00-footsteps.mp3"), loop: true, volume: 0.25, audio : null }, // DONE
          { type : 'sound', name: 'start', file: require("@/assets/media/01-hi-guys-weve-created.mp3"), playcount:1, loop: false, volume: 0.5, audio : null }, // DONE
          { type : 'sound', name: 'map', file: require("@/assets/media/04-map-what-have-we-got-here.mp3"), playcount:1, loop: true, volume: 0.5, audio : null }, // DONE
          { type : 'sound', name: 'wonder', file: require("@/assets/media/05-wonder-what-this-does.mp3"), playcount:1, loop: true, volume: 0.5, audio : null },
          { type : 'sound', name: 'warmer', file: require("@/assets/media/06-keep-going-youre-getting-warmer.mp3"), playcount:1, loop: true, volume: 0.5, audio : null }, // DONE
          { type : 'sound', name: 'dream', file: require("@/assets/media/07-wow-looks-like-a-dream-world.mp3"), playcount:1, loop: true, volume: 0.5, audio : null },
          { type : 'sound', name: 'recognise', file: require("@/assets/media/08-do-you-recognise-any-album-track-names.mp3"), playcount:1, loop: true, volume: 0.6, audio : null },
          { type : 'sound', name: 'congratulations', file: require("@/assets/media/03-congratulations-does-it-all-make-sense-now.mp3"), playcount:1, loop: true, volume: 0.5, audio : null },

        ];

        const loadedAssets = {}
        loadedAssets.textures = await loadTextures(  assets.filter(obj => { return obj.type === 'texture' }) );
        loadedAssets.sounds = await loadSounds(  assets.filter(obj => { return obj.type === 'sound' }) );
        loadedAssets.images = await loadImages(  assets.filter(obj => { return obj.type === 'image' }) );
        return loadedAssets;
      },

      onClick(){
        setTimeout(() => {
          this.started = true;
        }, 1000);
        this.game.resume();
      },

      onClickPause(){
        this.game.pause();
      },

      onClickEnd(){
        this.$router.push({ name: 'outro' });
      },

      onToggle(){
        this.audio = !this.audio;
        this.game.toggleAudio( this.audio );
      },

      toggleBodyClass(addRemoveClass, className) {
        const el = document.body;

        if (addRemoveClass === 'addClass') {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      },

    }
  }
</script>