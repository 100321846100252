import {
  Vector2,
} from "three";

class MiniMap {

  constructor( map, size = 80 ) {

    const CONFIG = window.CONFIG || null;

    size = {
      w: map[0].length * size,
      h: map.length * size/2,
      x: size,
      y: size/2,
    };

    const current = {
      position : null, // so we don't draw yellow on map till player has moved
      start :  new Vector2( 0, 0 ),
      end :  new Vector2( 0, 0 ),
      tile : null 
    }

    // const colours = {
    //   player : "yellow",
    //   goal : "yellow",
    //   empty : "white",
    //   wall : "rgb(175, 175, 175)",
    //   path : "black"
    // }

    const blue = '#172631';

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.setAttribute("width", size.w);
    canvas.setAttribute("height", size.h);

    ctx.fillStyle = blue;
    ctx.fillRect(0,0, size.w, size.h);

    // var bg = new Image();
    // bg.onload = ()=>{
    //   ctx.drawImage(bg, 110,520, 1200, 1200, 0, 0, this.size.w, this.size.h)
    // }

    // bg.src = require('@/assets/img/game/clipboard-back-desktop@2x.png');

    // const tile = new Image();
    // tile.onload = ()=>{
    //   generateMap();
    // };

    // tile.src = require('@/assets/img/game/map-container.png');

    const container = CONFIG.images.find(obj => { return obj.name === 'map-container' }).data
    const containerEnd = CONFIG.images.find(obj => { return obj.name === 'map-container-end' }).data

    const clear = (x,y)=>{
      ctx.fillStyle = blue;
      ctx.fillRect( x * size.x, y * size.y, size.x, size.y );
      //ctx.clearRect( x * size.x, y * size.y, size.x, size.y );
    }

    const drawPlayer = (x,y,colour = 'yellow')=>{
      ctx.fillStyle = colour;
      ctx.fillRect( (x * size.x) + (size.x/2) - (size.y/4),  (y * size.y) + size.y/2 - (size.y/4), size.y/2, size.y/2);
      // ctx.beginPath();
      // ctx.arc( x, y, 40, 0, 2 * Math.PI, 0);
      // ctx.fill();
    }

    // const drawRect = (x,y,colour  = 'yellow')=>{
    //   ctx.fillStyle = colour;
    //   ctx.fillRect( x * size.x, y * size.y, size.x, size.y );
    // }

    const drawWall = (x,y,data)=>{

      if( CONFIG.debug ){
        ctx.fillStyle = ( data == containerEnd ) ? 'yellow' : 'grey';
        ctx.fillRect( x * size.x, y * size.y, size.x, size.y );
      }else{
        ctx.drawImage(data, 0,0, 64, 32, x * size.x, y * size.y, size.x, size.y);
      }
      
    }

    let x,y,ly,lx;

    for (y = 0, ly = map.length; y < ly; y++) {
      for (x = 0, lx = map[x].length; x < lx; x++) {
        switch(map[y][x]){
          case 0: // WALL
            drawWall(x,y, container);
            break;
          case 1: // EMPTY
            // nothing to draw
            break;
          case 2: // START
            current.start.set(x,y);
            // drawRect(x,y);
            drawWall(x,y, container);
            break;
          case 3: // END
            current.end.set(x,y);
            // drawRect(x,y);
            drawWall(x,y, containerEnd);
            break;
        }
      }
    }
    
    this.update = function(pos) {
      if( current.position ) clear( current.position.x, current.position.y );
      drawPlayer(pos.x,pos.y);
      if( current.tile === 0 || current.tile === 2 ) drawWall(current.position.x, current.position.y, container); // wall or start
      if( current.tile === 3 ) drawWall(current.position.x, current.position.y, containerEnd); // yellow end container
      
      if(!current.position) current.position = new Vector2(0,0);
      current.position.set(pos.x,pos.y);
      current.tile = pos.tile;
    };

    this.get = function() {
      return canvas;
    }

    return this;
  }
}
export { MiniMap }