import {
  EquirectangularReflectionMapping,
  sRGBEncoding
} from "three";

class Background {

  constructor() {

    const CONFIG = window.CONFIG || null;

    let textureEquirec
    if( !CONFIG.textures ) return null;

    textureEquirec = CONFIG.textures.find(obj => { return obj.name === 'background' }).map;
    textureEquirec.mapping = EquirectangularReflectionMapping;
    textureEquirec.encoding = sRGBEncoding;

    this.get = function(){
      return textureEquirec;
    }

    return this;
  }

}

export { Background };