import { randomInt } from './functions.js';
class Maps {

  constructor( level = 0 ) {

    let maps = [];

    maps.push([ //1
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [0,1,1,1,1,0,1,1,1,1,1,1,1,0,1,0],
      [0,1,0,0,1,1,1,0,0,0,0,0,1,1,1,0],
      [0,1,1,1,0,0,1,0,1,1,1,1,0,1,1,0],
      [0,0,1,0,1,1,0,1,1,0,1,0,1,1,0,0],
      [0,1,1,0,1,0,1,1,1,1,1,0,1,0,1,0],
      [0,1,0,0,1,1,1,8,1,1,0,0,1,1,1,0],
      [0,1,1,1,0,1,0,1,0,1,1,1,0,1,0,0],
      [0,1,0,0,0,0,0,1,1,0,0,1,0,1,0,0],
      [0,1,0,1,0,1,1,0,1,1,0,1,0,1,1,0],
      [0,1,0,1,1,0,1,0,0,1,0,1,1,0,1,0],
      [0,1,1,0,1,0,1,1,0,1,1,0,1,0,1,0],
      [0,1,1,0,1,1,1,1,0,0,1,0,1,8,1,0],
      [0,0,1,1,0,1,0,1,1,0,1,1,0,1,0,0],
      [0,0,1,0,1,1,0,0,1,1,1,0,1,1,1,0],
      [0,1,1,1,0,0,1,1,1,0,1,1,1,0,1,0],
      [0,1,0,0,1,0,0,0,1,0,0,0,0,1,1,0],
      [0,1,0,1,1,1,1,1,1,0,1,1,1,0,1,0],
      [0,1,0,0,1,0,1,2,0,0,1,0,1,1,1,0],
      [0,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0],
      [0,0,1,0,1,1,0,0,1,0,0,0,1,1,0,0],
      [0,1,1,0,1,0,1,1,1,1,1,0,1,0,1,0],
      [0,1,0,0,1,1,1,0,1,4,0,1,1,1,1,0],
      [0,1,1,1,0,1,0,1,1,0,1,1,0,7,0,0],
      [0,1,0,1,0,1,0,0,1,1,0,1,0,1,0,0],
      [0,1,0,1,0,1,1,1,1,0,0,1,0,1,1,0],
      [0,1,0,1,6,0,0,0,0,1,1,1,1,0,1,0],
      [0,7,1,0,1,0,1,1,1,0,1,0,1,0,1,0],
      [0,0,1,0,1,1,1,0,1,0,0,0,1,1,1,0],
      [0,0,1,1,0,1,0,0,1,5,1,1,0,1,0,0],
      [0,1,1,0,1,1,1,1,1,0,1,3,0,1,1,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    ]);

    maps.push([ // 2
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [0,1,1,1,6,1,1,0,1,1,1,5,1,1,1,0],
      [0,1,0,0,1,1,0,1,1,0,0,0,1,1,3,0],
      [0,1,0,1,1,0,1,1,0,1,1,2,0,1,1,0],
      [0,1,0,1,0,1,1,0,1,1,1,0,1,0,0,0],
      [0,1,0,1,1,1,0,4,1,1,0,1,1,0,1,0],
      [0,1,0,0,0,0,1,1,0,1,1,0,1,1,1,0],
      [0,1,1,0,1,1,1,1,0,1,1,1,0,1,1,0],
      [0,0,1,1,0,1,1,0,0,0,0,1,1,0,1,0],
      [0,1,0,1,1,0,1,1,1,1,0,1,1,0,1,0],
      [0,1,1,0,1,1,0,1,0,1,1,1,0,1,7,0],
      [0,1,1,1,0,1,0,1,0,1,1,0,1,1,0,0],
      [0,1,0,1,1,1,0,1,1,0,1,1,1,0,1,0],
      [0,1,1,0,1,0,1,0,0,1,1,0,1,1,1,0],
      [0,0,1,1,0,1,1,1,1,1,8,1,1,0,1,0],
      [0,1,0,1,1,0,0,0,0,1,1,0,1,0,1,0],
      [0,1,1,0,1,1,1,1,1,0,1,1,0,1,1,0],
      [0,1,1,1,0,1,1,0,1,0,1,0,1,1,0,0],
      [0,1,0,0,1,0,1,1,0,1,1,0,0,1,1,0],
      [0,1,1,1,1,1,0,7,1,0,1,1,1,0,1,0],
      [0,1,0,1,0,1,1,0,1,1,0,1,1,0,1,0],
      [0,1,0,1,0,0,1,1,0,1,1,0,1,0,1,0],
      [0,1,1,0,1,1,0,1,1,0,1,0,1,0,1,0],
      [0,0,1,1,0,1,1,0,1,1,1,0,0,1,1,0],
      [0,1,0,1,1,8,1,1,0,0,0,1,1,0,1,0],
      [0,1,1,0,1,1,0,0,1,1,1,1,0,1,1,0],
      [0,1,1,1,0,1,0,1,1,1,0,0,1,1,1,0],
      [0,1,0,0,1,1,0,1,1,0,1,1,1,0,1,0],
      [0,1,1,1,1,0,1,1,0,1,1,1,0,1,1,0],
      [0,1,0,0,0,0,1,0,1,1,1,0,1,1,0,0],
      [0,1,1,1,1,1,1,1,1,0,1,1,1,1,1,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    ]);

    maps.push([ // 3
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [0,1,1,0,1,0,1,1,1,1,1,0,1,1,1,0],
      [0,0,1,1,1,1,1,0,0,1,0,1,1,0,1,0],
      [0,0,1,2,1,0,1,1,1,0,1,1,0,1,1,0],
      [0,1,1,1,1,1,1,1,0,1,1,1,7,1,0,0],
      [0,1,0,1,0,1,0,1,1,1,0,0,0,1,1,0],
      [0,1,1,1,1,1,1,1,8,1,1,0,1,1,0,0],
      [0,0,1,4,1,0,1,0,1,0,1,0,1,0,1,0],
      [0,1,1,0,1,1,1,1,1,0,1,1,0,1,1,0],
      [0,1,1,1,0,1,0,1,0,1,0,1,1,1,1,0],
      [0,1,0,1,1,1,1,1,0,1,1,0,0,0,1,0],
      [0,1,1,1,0,1,1,1,0,0,1,1,1,1,1,0],
      [0,0,0,0,1,1,0,1,0,0,0,1,0,0,0,0],
      [0,1,1,1,1,0,1,1,1,1,1,1,1,1,1,0],
      [0,1,0,0,0,1,1,0,1,1,0,0,0,1,0,0],
      [0,1,1,1,1,0,1,0,1,0,1,1,1,1,1,0],
      [0,0,0,0,1,0,1,0,1,0,1,0,1,0,1,0],
      [0,1,1,1,1,1,1,1,1,1,1,0,1,0,1,0],
      [0,1,0,0,1,0,0,0,1,0,1,0,0,0,1,0],
      [0,1,1,1,1,1,1,1,8,1,1,0,1,1,1,0],
      [0,1,0,1,0,0,0,1,1,0,1,1,1,0,0,0],
      [0,1,0,1,1,0,1,1,0,1,1,0,1,1,1,0],
      [0,1,1,0,1,0,1,0,1,0,1,0,0,0,1,0],
      [0,0,1,1,0,1,1,1,1,1,1,1,1,1,1,0],
      [0,1,0,1,1,1,0,1,0,1,0,0,0,1,0,0],
      [0,1,1,0,0,0,1,1,1,0,1,1,1,1,1,0],
      [0,1,1,1,1,1,1,0,1,1,1,0,0,0,0,0],
      [0,1,0,7,0,1,1,0,0,1,0,1,1,5,1,0],
      [0,1,0,1,1,0,1,1,0,6,0,1,3,0,1,0],
      [0,1,1,0,1,1,0,1,0,1,1,0,0,1,1,0],
      [0,1,1,1,0,1,1,0,1,0,1,1,1,1,0,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    ]);

    maps.push([ // 4
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [0,1,1,1,1,0,0,1,0,1,1,1,1,0,1,0],
      [0,1,0,1,1,1,1,1,1,0,0,1,1,1,1,0],
      [0,1,0,1,0,1,1,0,1,1,1,1,0,1,1,0],
      [0,1,0,1,1,0,1,1,0,1,1,0,1,0,1,0],
      [0,1,1,0,1,1,0,1,1,0,0,1,1,1,0,0],
      [0,0,8,1,0,0,1,0,1,1,1,1,0,1,1,0],
      [0,1,1,0,1,1,1,1,0,1,1,0,1,1,1,0],
      [0,1,1,0,1,0,0,1,1,0,0,1,1,0,1,0],
      [0,0,1,0,1,1,0,0,1,1,1,0,0,1,1,0],
      [0,0,1,1,0,5,1,1,0,0,6,1,1,1,1,0],
      [0,1,0,1,1,0,0,1,1,1,3,0,1,0,0,0],
      [0,1,1,1,0,1,1,1,0,0,1,0,1,1,1,0],
      [0,1,1,0,1,0,0,0,1,1,1,0,0,1,0,0],
      [0,1,0,1,1,0,1,1,7,0,0,1,1,1,1,0],
      [0,1,0,1,0,1,1,0,0,1,0,0,1,1,1,0],
      [0,1,1,1,0,1,0,1,1,1,1,1,0,1,0,0],
      [0,1,0,0,1,1,1,1,0,1,0,1,1,1,1,0],
      [0,1,1,1,1,0,0,1,0,1,1,0,1,0,0,0],
      [0,1,0,1,0,1,0,1,1,0,1,0,1,1,1,0],
      [0,1,1,0,1,1,1,0,1,0,1,1,0,0,1,0],
      [0,0,1,1,1,0,1,0,1,1,0,1,1,8,1,0],
      [0,1,0,1,0,0,1,1,0,1,1,0,0,0,0,0],
      [0,1,1,1,1,1,0,1,1,0,1,1,1,1,1,0],
      [0,0,1,0,0,1,1,0,1,1,0,0,0,1,0,0],
      [0,1,1,0,1,0,1,0,7,1,1,1,1,1,1,0],
      [0,1,1,1,4,1,0,0,1,0,1,0,1,0,1,0],
      [0,1,0,0,1,1,0,1,1,0,1,1,1,1,0,0],
      [0,1,1,1,0,0,1,0,1,1,0,1,0,1,1,0],
      [0,1,2,0,1,0,1,0,0,1,0,1,1,0,0,0],
      [0,1,1,1,1,1,1,1,1,1,1,0,1,1,1,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    ]);

    maps.push([ // 5
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      [0,1,1,1,1,1,0,1,1,1,1,0,1,1,1,0],
      [0,1,0,1,0,1,0,1,0,1,1,1,1,0,1,0],
      [0,1,0,1,0,1,0,1,1,0,1,1,0,1,1,0],
      [0,1,1,0,1,8,1,0,1,1,0,1,0,0,1,0],
      [0,0,1,0,1,0,1,1,0,1,0,1,1,1,0,0],
      [0,0,1,0,1,0,0,1,1,1,1,0,1,1,0,0],
      [0,0,1,0,1,1,1,0,0,1,0,1,0,1,1,0],
      [0,0,1,1,0,1,0,1,1,0,1,1,1,0,1,0],
      [0,1,0,1,1,0,1,1,0,1,1,0,1,0,1,0],
      [0,1,1,0,1,0,1,0,1,1,0,1,0,1,6,0],
      [0,1,0,1,1,0,1,0,1,0,1,1,1,1,1,0],
      [0,1,0,1,0,0,1,1,1,0,1,0,1,0,1,0],
      [0,1,1,1,1,1,0,1,0,1,1,1,0,1,1,0],
      [0,1,0,0,1,0,1,1,0,1,0,1,1,1,0,0],
      [0,1,1,1,0,1,0,1,0,5,1,0,1,0,1,0],
      [0,1,0,1,1,1,1,1,1,0,1,1,3,0,7,0],
      [0,1,1,0,0,0,0,1,0,0,0,0,1,0,1,0],
      [0,1,1,1,1,1,1,1,1,1,1,2,4,0,1,0],
      [0,1,0,0,0,1,0,0,1,0,1,1,1,1,1,0],
      [0,1,0,1,1,0,1,1,1,0,1,0,0,1,1,0],
      [0,1,1,1,0,1,1,0,1,1,1,1,1,0,1,0],
      [0,1,1,0,1,1,0,1,1,1,0,0,1,1,1,0],
      [0,1,0,1,1,0,1,1,0,1,1,1,0,1,0,0],
      [0,0,7,1,0,1,1,0,0,0,0,1,0,1,0,0],
      [0,1,1,0,1,1,1,1,1,1,1,1,0,1,0,0],
      [0,0,0,1,1,0,0,0,1,0,1,0,1,1,0,0],
      [0,1,1,1,1,1,1,1,1,0,1,1,0,1,1,0],
      [0,1,0,0,0,8,0,0,1,1,0,1,0,1,1,0],
      [0,1,1,1,1,1,1,1,1,0,1,1,1,0,1,0],
      [0,1,0,1,1,0,0,1,0,1,1,0,1,1,1,0],
      [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    ]);

    
    let levelIndex = ( level > 0 && level < maps.length ) ? level-1 : randomInt(0,maps.length-1);

    if( level == 666 ){ /* DEMO */ 
      levelIndex = 0;
      maps = [];
      maps.push( [
        [0,0,0,0,0,0,0,0],
        [0,4,1,1,1,0,1,0],
        [0,1,0,0,1,1,1,0],
        [0,1,1,1,0,1,2,0],
        [0,0,1,0,1,1,0,0],
        [0,1,1,0,1,0,1,0],
        [0,1,0,1,1,8,1,0],
        [0,1,1,1,0,1,0,0],
        [0,6,0,1,0,1,0,0],
        [0,1,0,1,0,1,1,0],
        [0,1,0,1,1,0,1,0],
        [0,5,1,0,1,0,1,0],
        [0,1,1,0,1,7,1,0],
        [0,0,1,3,0,1,0,0],
        [0,1,1,0,1,1,1,0],
        [0,0,0,0,0,0,0,0]
      ]);
    }
    
    this.get = function(){
      return maps[levelIndex];
    }

    this.getLevel = function(){
      return levelIndex+1;
    }

    this.getTile = function(pos){
      return ( maps[levelIndex][pos.y] && maps[levelIndex][pos.y][pos.x] ) ? maps[levelIndex][pos.y][pos.x] : 0; // return map title or zero when undefined (i.e. off map)
    }
    
    return this;
  }
}
export { Maps }