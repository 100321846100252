<template>
  <div class="toggle"> 
    <h3 v-if="label" class="gold">{{label}}</h3>
    <label :for="label" >
      <input v-on:click="$emit('click')" type="checkbox" :id="label">
      <span class="slider"></span>
      <h4 v-if="unchecked">{{unchecked}}</h4>
      <h4 v-if="checked">{{checked}}</h4>
    </label>
  </div>
</template>
<script>
  export default {
    name: 'Toggle',
    props: {
      label:{
        default: null,
        type: String || null
      },
      unchecked: {
        default: null,
        type: String || null
      },
      checked: {
         default: null,
        type: String || null
      }
    }
  }
</script>