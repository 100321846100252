<template>
  <div class="form">
    <p class="small">To be in with a chance of winning 2 VIP tickets to a James Arthur headline live show in 2022 of your choice, including meet & greet, enter below. 5 runners up will win an exclusive signed bespoke "RIDE" license plate from the Ride official video.</p>

    <br>

    <h3 class="gold">ENTER VIA</h3>

    <br>

    <Toggle class="full-width" :unchecked="'SPOTIFY'" :checked="'EMAIL'" v-on:click="onToggle"></Toggle>

    <br v-if="!spotify">

    <!-- <Input? v-if="!spotify" :email="input.email" :label="'Email'" :placeholder="'name@email.com'" /> -->

    <label v-if="!spotify" class="input">
      <h5 class="gold">Email</h5>
      <input v-model="input.email" type="email" :placeholder="'name@email.com'" required/>
    </label>

    <br v-if="!spotify && input.terms.newsletter">

    <label v-if="!spotify && input.terms.newsletter" class="input">
      <h5 class="gold">First Name</h5>
      <input v-model="input.first_name" type="text" :placeholder="'hello'" required/>
    </label>

    <br v-if="!spotify && input.terms.newsletter">

    <label v-if="!spotify && input.terms.newsletter" class="input">
      <h5 class="gold">Last Name</h5>
      <input v-model="input.last_name" type="text" :placeholder="'world'" required/>
    </label>

    <br v-if="!spotify && input.terms.newsletter">

    <div class="input-wrap" v-if="!spotify && input.terms.newsletter">

      <h5 class="gold">Date of Brith</h5>

      <br>

      <div class="inputs">

      <label class="input inline">
        <h5 class="gold">Date</h5>
        <select v-model="input.dob.day">
          <option v-for="day in days" :value="day" :key="day">{{ day }}</option>
        </select>
      </label>

      <label class="input inline">
        <h5 class="gold">Month</h5>
        <select v-model="input.dob.month">
          <option v-for="month in months" :value="month" :key="month">{{ month }}</option>
        </select>
      </label>

      <label class="input inline">
        <h5 class="gold">Year</h5>
        <select v-model="input.dob.year">
          <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
        </select>
      </label>

      </div>

    </div>

    <br v-if="!spotify && input.terms.newsletter">

    <label v-if="!spotify && input.terms.newsletter" class="input">
      <h5 class="gold">Country</h5>
      <select v-model="input.country_code">
					<option value="AF">Afghanistan</option>
							<option value="AL">Albania</option>
							<option value="DZ">Algeria</option>
							<option value="AS">American Samoa</option>
							<option value="AD">Andorra</option>
							<option value="AO">Angola</option>
							<option value="AI">Anguilla</option>
							<option value="AQ">Antarctica</option>
							<option value="AG">Antigua & Barbuda</option>
							<option value="AR">Argentina</option>
							<option value="AM">Armenia</option>
							<option value="AW">Aruba</option>
							<option value="AU">Australia</option>
							<option value="AT">Austria</option>
							<option value="AZ">Azerbaijan</option>
							<option value="BS">Bahamas</option>
							<option value="BH">Bahrain</option>
							<option value="BD">Bangladesh</option>
							<option value="BB">Barbados</option>
							<option value="BY">Belarus</option>
							<option value="BE">Belgium</option>
							<option value="BZ">Belize</option>
							<option value="BJ">Benin</option>
							<option value="BM">Bermuda</option>
							<option value="BT">Bhutan</option>
							<option value="BO">Bolivia</option>
							<option value="BA">Bosnia & Herzegovina</option>
							<option value="BW">Botswana</option>
							<option value="BV">Bouvet Island</option>
							<option value="BR">Brazil</option>
							<option value="IO">British Indian Ocean Territory</option>
							<option value="VG">British Virgin Islands</option>
							<option value="BN">Brunei</option>
							<option value="BG">Bulgaria</option>
							<option value="BF">Burkina Faso</option>
							<option value="BI">Burundi</option>
							<option value="KH">Cambodia</option>
							<option value="CM">Cameroon</option>
							<option value="CA">Canada</option>
							<option value="CV">Cape Verde</option>
							<option value="BQ">Caribbean Netherlands</option>
							<option value="KY">Cayman Islands</option>
							<option value="CF">Central African Republic</option>
							<option value="TD">Chad</option>
							<option value="CL">Chile</option>
							<option value="CN">China</option>
							<option value="CX">Christmas Island</option>
							<option value="CC">Cocos (Keeling) Islands</option>
							<option value="CO">Colombia</option>
							<option value="KM">Comoros</option>
							<option value="CG">Congo - Brazzaville</option>
							<option value="CD">Congo - Kinshasa</option>
							<option value="CK">Cook Islands</option>
							<option value="CR">Costa Rica</option>
							<option value="HR">Croatia</option>
							<option value="CU">Cuba</option>
							<option value="CW">Curaçao</option>
							<option value="CY">Cyprus</option>
							<option value="CZ">Czechia</option>
							<option value="CI">Côte d’Ivoire</option>
							<option value="DK">Denmark</option>
							<option value="DJ">Djibouti</option>
							<option value="DM">Dominica</option>
							<option value="DO">Dominican Republic</option>
							<option value="EC">Ecuador</option>
							<option value="EG">Egypt</option>
							<option value="SV">El Salvador</option>
							<option value="GQ">Equatorial Guinea</option>
							<option value="ER">Eritrea</option>
							<option value="EE">Estonia</option>
							<option value="SZ">Eswatini</option>
							<option value="ET">Ethiopia</option>
							<option value="FK">Falkland Islands</option>
							<option value="FO">Faroe Islands</option>
							<option value="FJ">Fiji</option>
							<option value="FI">Finland</option>
							<option value="FR">France</option>
							<option value="GF">French Guiana</option>
							<option value="PF">French Polynesia</option>
							<option value="TF">French Southern Territories</option>
							<option value="GA">Gabon</option>
							<option value="GM">Gambia</option>
							<option value="GE">Georgia</option>
							<option value="DE">Germany</option>
							<option value="GH">Ghana</option>
							<option value="GI">Gibraltar</option>
							<option value="GR">Greece</option>
							<option value="GL">Greenland</option>
							<option value="GD">Grenada</option>
							<option value="GP">Guadeloupe</option>
							<option value="GU">Guam</option>
							<option value="GT">Guatemala</option>
							<option value="GG">Guernsey</option>
							<option value="GN">Guinea</option>
							<option value="GW">Guinea-Bissau</option>
							<option value="GY">Guyana</option>
							<option value="HT">Haiti</option>
							<option value="HM">Heard & McDonald Islands</option>
							<option value="HN">Honduras</option>
							<option value="HK">Hong Kong SAR China</option>
							<option value="HU">Hungary</option>
							<option value="IS">Iceland</option>
							<option value="IN">India</option>
							<option value="ID">Indonesia</option>
							<option value="IR">Iran</option>
							<option value="IQ">Iraq</option>
							<option value="IE">Ireland</option>
							<option value="IM">Isle of Man</option>
							<option value="IL">Israel</option>
							<option value="IT">Italy</option>
							<option value="JM">Jamaica</option>
							<option value="JP">Japan</option>
							<option value="JE">Jersey</option>
							<option value="JO">Jordan</option>
							<option value="KZ">Kazakhstan</option>
							<option value="KE">Kenya</option>
							<option value="KI">Kiribati</option>
							<option value="XK">Kosovo</option>
							<option value="KW">Kuwait</option>
							<option value="KG">Kyrgyzstan</option>
							<option value="LA">Laos</option>
							<option value="LV">Latvia</option>
							<option value="LB">Lebanon</option>
							<option value="LS">Lesotho</option>
							<option value="LR">Liberia</option>
							<option value="LY">Libya</option>
							<option value="LI">Liechtenstein</option>
							<option value="LT">Lithuania</option>
							<option value="LU">Luxembourg</option>
							<option value="MO">Macao SAR China</option>
							<option value="MG">Madagascar</option>
							<option value="MW">Malawi</option>
							<option value="MY">Malaysia</option>
							<option value="MV">Maldives</option>
							<option value="ML">Mali</option>
							<option value="MT">Malta</option>
							<option value="MH">Marshall Islands</option>
							<option value="MQ">Martinique</option>
							<option value="MR">Mauritania</option>
							<option value="MU">Mauritius</option>
							<option value="YT">Mayotte</option>
							<option value="MX">Mexico</option>
							<option value="FM">Micronesia</option>
							<option value="MD">Moldova</option>
							<option value="MC">Monaco</option>
							<option value="MN">Mongolia</option>
							<option value="ME">Montenegro</option>
							<option value="MS">Montserrat</option>
							<option value="MA">Morocco</option>
							<option value="MZ">Mozambique</option>
							<option value="MM">Myanmar (Burma)</option>
							<option value="NA">Namibia</option>
							<option value="NR">Nauru</option>
							<option value="NP">Nepal</option>
							<option value="NL">Netherlands</option>
							<option value="NC">New Caledonia</option>
							<option value="NZ">New Zealand</option>
							<option value="NI">Nicaragua</option>
							<option value="NE">Niger</option>
							<option value="NG">Nigeria</option>
							<option value="NU">Niue</option>
							<option value="NF">Norfolk Island</option>
							<option value="KP">North Korea</option>
							<option value="MK">North Macedonia</option>
							<option value="MP">Northern Mariana Islands</option>
							<option value="NO">Norway</option>
							<option value="OM">Oman</option>
							<option value="PK">Pakistan</option>
							<option value="PW">Palau</option>
							<option value="PS">Palestinian Territories</option>
							<option value="PA">Panama</option>
							<option value="PG">Papua New Guinea</option>
							<option value="PY">Paraguay</option>
							<option value="PE">Peru</option>
							<option value="PH">Philippines</option>
							<option value="PN">Pitcairn Islands</option>
							<option value="PL">Poland</option>
							<option value="PT">Portugal</option>
							<option value="PR">Puerto Rico</option>
							<option value="QA">Qatar</option>
							<option value="RO">Romania</option>
							<option value="RU">Russia</option>
							<option value="RW">Rwanda</option>
							<option value="RE">Réunion</option>
							<option value="WS">Samoa</option>
							<option value="SM">San Marino</option>
							<option value="SA">Saudi Arabia</option>
							<option value="SN">Senegal</option>
							<option value="RS">Serbia</option>
							<option value="SC">Seychelles</option>
							<option value="SL">Sierra Leone</option>
							<option value="SG">Singapore</option>
							<option value="SX">Sint Maarten</option>
							<option value="SK">Slovakia</option>
							<option value="SI">Slovenia</option>
							<option value="SB">Solomon Islands</option>
							<option value="SO">Somalia</option>
							<option value="ZA">South Africa</option>
							<option value="GS">South Georgia & South Sandwich Islands</option>
							<option value="KR">South Korea</option>
							<option value="SS">South Sudan</option>
							<option value="ES">Spain</option>
							<option value="LK">Sri Lanka</option>
							<option value="BL">St. Barthélemy</option>
							<option value="SH">St. Helena</option>
							<option value="KN">St. Kitts & Nevis</option>
							<option value="LC">St. Lucia</option>
							<option value="MF">St. Martin</option>
							<option value="PM">St. Pierre & Miquelon</option>
							<option value="VC">St. Vincent & Grenadines</option>
							<option value="SD">Sudan</option>
							<option value="SR">Suriname</option>
							<option value="SJ">Svalbard & Jan Mayen</option>
							<option value="SE">Sweden</option>
							<option value="CH">Switzerland</option>
							<option value="SY">Syria</option>
							<option value="ST">São Tomé & Príncipe</option>
							<option value="TW">Taiwan</option>
							<option value="TJ">Tajikistan</option>
							<option value="TZ">Tanzania</option>
							<option value="TH">Thailand</option>
							<option value="TL">Timor-Leste</option>
							<option value="TG">Togo</option>
							<option value="TK">Tokelau</option>
							<option value="TO">Tonga</option>
							<option value="TT">Trinidad & Tobago</option>
							<option value="TN">Tunisia</option>
							<option value="TR">Turkey</option>
							<option value="TM">Turkmenistan</option>
							<option value="TC">Turks & Caicos Islands</option>
							<option value="TV">Tuvalu</option>
							<option value="UM">U.S. Outlying Islands</option>
							<option value="VI">U.S. Virgin Islands</option>
							<option value="UG">Uganda</option>
							<option value="UA">Ukraine</option>
							<option value="AE">United Arab Emirates</option>
							<option value="GB">United Kingdom</option>
							<option value="US">United States</option>
							<option value="UY">Uruguay</option>
							<option value="UZ">Uzbekistan</option>
							<option value="VU">Vanuatu</option>
							<option value="VA">Vatican City</option>
							<option value="VE">Venezuela</option>
							<option value="VN">Vietnam</option>
							<option value="WF">Wallis & Futuna</option>
							<option value="EH">Western Sahara</option>
							<option value="YE">Yemen</option>
							<option value="ZM">Zambia</option>
							<option value="ZW">Zimbabwe</option>
							<option value="AX">Åland Islands</option>
						</select>
    </label>

    <br>

    <Checkbox  :label="'privacy'" v-on:click="onChecked('privacy')">I have read and understood the <a href="https://tools.sonymusiccreative.com/privacy/" rel="noreferrer noopener" target="_blank" alt="privacy">Privacy & Cookie Policy</a> and agree to the processing of my data and the use of cookies in accordance with it.</Checkbox>

    <br>

    <Checkbox  :label="'terms'" v-on:click="onChecked('terms')">I have read and understood the <a href="./terms-and-conditions.pdf" rel="noreferrer noopener" target="_blank" alt="terms">Terms & Conditions</a>.</Checkbox>

    <!-- <br>
    
    <Checkbox :label="'newsletter'" v-on:click="onChecked('newsletter')">I'd also like to receive news from James Arthur and Sony Music.</Checkbox> -->

    <br v-if="input.error">

    <p v-if="input.error" class="small gold">{{input.error}}</p>

    <p v-else class="small">&nbsp;</p>

    <br>
    
    <Button v-if="spotify" class="spotify" :disabled="!input.terms.privacy || !input.terms.terms" v-on:click="onAuthenticate('spotify')">Connect with Spotify</Button>
    <Button v-if="!spotify" :disabled="!input.terms.privacy || !input.terms.terms" v-on:click="onAuthenticate('email')">Enter with your email</Button>
    
  <br v-show="spotify">
  <p v-show="spotify" class="small">By entering with Spotify you will save the album It'll All Make Sense In The End and follow James Arthur.</p>

  </div>
</template>
<script>

  import axios from "axios";
  import Button from './Button.vue';
  import Toggle from './Toggle.vue';
  import Checkbox from './Checkbox.vue';

  export default {
    name: 'Authentication',
    components: {
      Button,
      Toggle,
      Checkbox,
    },

    data() {
      return { /* No longer including newsletter sign up but might useful later ?! */ 
        spotify: true,
        user: null,
        input: {
          error: false,
          email: "",
          dob: { 
            year : "",
            month : "",
            day : "",
          },
          country_code: "",
          first_name: "",
          last_name: "",
          terms : {
            privacy : false,
            terms : false,
            newsletter :false,
          }
        },
      };
    },

    computed : {
      years () {
        const year = new Date().getFullYear()
        return Array.from({length: year - 1900}, (value, index) => 1901 + index)
      },
      months() {
        return Array.from({length: 13 - 1}, (value, index) => {
          let month = (1 + index).toString();
          return ( month.length < 2 ) ? '0' + month : month;
        });
      },
      days() {
        return Array.from({length: 32 - 1}, (value, index) => {
          let day = (1 + index).toString();
          return ( day.length < 2 ) ? '0' + day : day;
        })
      }
    },

    mounted() {
      document.addEventListener("AE_AUTHED", this.onAuthed); // for AE callback only
    },

    methods: {

      onToggle() {
        this.spotify = !this.spotify;
      },

      onChecked(service) {
        this.input.terms[service] = !this.input.terms[service];
      },

      onError( message ){
        this.input.error = message;
        setTimeout(() => {
          this.input.error = false;
        }, 3000);
      },

      validateEmail(email) {
        var re = /^[^\s@]+@[^\s@]+$/;
        return re.test(email);
      },

      validateDob(dob) {
        var re = /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/;
        return re.test(dob);
      },

      onAuthComplete(reload) {
        this.input.email = "";
        localStorage.setItem(
          "Authentication",
          JSON.stringify({
            complete: true,
            service : ( this.spotify ) ? 'Spotify' : 'Email',
            ...(this.user && { user: this.user }),
            // ...(this.service && { service: this.service }),
          })
        );
        //  //localStorage.removeItem('Authentication');
        if (reload) window.location.reload(false);
      },

      onAuthenticate(service) {

        //console.log("ON AUTHENTICATE", service);

        // AUTH
        switch (service) {
          case "spotify":

            if ( !this.input.terms.privacy || !this.input.terms.terms ) {
              console.error("Not accepted terms");
              this.onError('To enter this competition you need to agree to the terms');
              return null;
            }

            if( !window.aeJS || window.location.host == "localhost:8080" ){
              console.error("No AE probably beacuse we are local?!");
              return null;
            }
            window.aeJS.trigger.authenticate(
              service,
              "register",
              window.location.href
            );
            break;

          case "email":

            if ( !this.input.terms.privacy || !this.input.terms.terms ) {
              console.error("Not accepted terms");
              this.onError('To enter this competition you need to agree to the terms');
              return null;
            }

            if ( !this.input.email || this.input.email.length < 3 ) {
              console.error("Missing email or too short");
              this.onError('To enter this competition provide a valid email address');
              return null;
            }

            if ( !this.validateEmail(this.input.email) ) {
              console.error("Not an email");
              this.onError('Please check your email address and try again');
              return null;
            }

            if ( this.input.terms.newsletter && (!this.input.first_name || this.input.email.first_name < 3) ) {
              console.error("Missing first name");
              this.onError('To sign up to the newsletter we require your Frist Name');
              return null;
            }

            if ( this.input.terms.newsletter && (!this.input.last_name || this.input.email.last_name < 3) ) {
              console.error("Missing lastname");
              this.onError('To sign up to the newsletter we require your Last Name');
              return null;
            }

            var dob = this.input.dob.year + '-' + this.input.dob.month + '-' + this.input.dob.day;

            if ( this.input.terms.newsletter && ( !dob || !this.validateDob(dob) ) ) {
              console.error("Missing dob or not in YYYY-MM-DD format");
              this.onError('To sign up to the newsletter we require your Date of Birth');
              return null;
            }

            if ( this.input.terms.newsletter && (!this.input.country_code || this.input.email.country_code < 3) ) {
              console.error("Missing country");
              this.onError('Please choose a country before signing up to the newsletter');
              return null;
            }

            this.submitSMF_xhr(
              {
                field_email_address: this.input.email,
                source_channel: 'Email',
                ...(this.input.first_name && {
                  field_first_name: this.input.first_name,
                }),
                ...(this.input.lastname && {
                  field_last_name: this.input.last_name,
                }),
                ...(this.input.country && {
                  field_country_region: this.input.country,
                }),
                ...(this.input.terms.newsletter && dob && {
                  field_dob: dob,
                }),
                ...(this.input.country_code && {
                  field_country_code: this.input.country_code,
                }),
              },
              ( this.input.terms.newsletter ) ? '{"actions":{"formsubmission":75094},"mailing_list_optins":{"a0S61000000Zh4lEAC":75095}}' : '{"actions":{"formsubmission":75094}}' );

            this.onAuthComplete(true);
            break;
        }
      },

      onAuthed(evt) {

        if (!evt.detail) return;

        this.user = evt.detail;

        console.log("ON_AE_AUTHED", this.user);

        const accessToken = JSON.parse(this.user.auth_token).access_token;

        // Remove hash from URL so it's not visible to user
        const url = new URL(location);
        url.searchParams.delete("accessToken");
        url.searchParams.delete("ae_session");
        history.replaceState(null, null, url);
        console.log("Access token", accessToken);

        if (this.user.services[0].Service === "spotify") {
          this.spotifyFollow(accessToken, "4IWBUUAFIplrNtaOHcJPRM");
          this.spotifyGetAlbum(accessToken, "0P4xzdUBgd8cMncwM7okcd");
        }

        this.submitSMF_xhr( /* save to SONY MUSIC FAN */ 
          {
            field_email_address: this.user.data.Email,
            source_channel: this.user.services[0].Service.charAt(0).toUpperCase() + this.user.services[0].Service.slice(1), // 'Spotify'
            ae_member_id: this.user.data.ID,
            ...(this.user.data.FirstName && {
              field_first_name: this.user.data.FirstName,
            }),
            ...(this.user.data.Surname && {
              field_last_name: this.user.data.Surname,
            }),
            ...(this.user.data.Country && {
              field_country_region: this.user.data.Country,
            }),
            ...(this.user.data.CountryCode && {
              field_country_code: this.user.data.CountryCode,
            }),
          },
          ( this.input.terms.newsletter) ? '{"actions":{"presave":75093},"mailing_list_optins":{"a0S61000000Zh4lEAC":75095}}' : '{"actions":{"presave":75093}}'
        );

        this.onAuthComplete(true);
      },

      spotifyFollow: (accessToken, artistId) => {
        // https://developer.spotify.com/documentation/web-api/reference/follow/follow-artists-users/

        axios({
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          url: `https://api.spotify.com/v1/me/following?type=artist&ids=${artistId}`,
        }).then(
          (response) => {
            console.log("spotifyFollow.success", response);
          },
          (error) => {
            console.log("spotifyFollow.error", error);
          }
        );
      },

      spotifyGetAlbum: (accessToken, album_ids) => {
        // GET https://api.spotify.com/v1/albums/{id}
        // https://open.spotify.com/album/1SLgJeTdzDAJLcDyQqoWnu?si=LolbZBx3TIervpoAn-a8zA
        // https://open.spotify.com/album/1168pCxg0HeTRqyutFf4o1?si=PtRWRY_PT_qaR03X0anf1w
        // https://developer.spotify.com/console/put-current-user-saved-albums/?ids=07bYtmE3bPsLB6ZbmmFi8d%2C48JYNjh7GMie6NjqYHMmtT%2C27cZdqrQiKt3IT00338dws

        axios({
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          url: `https://api.spotify.com/v1/me/albums/?ids=${album_ids}`,
        }).then(
          (response) => {
            console.log("spotifyGetAlbum.success", response);
          },
          (error) => {
            console.log("spotifyGetAlbum.error", error);
          }
        );
      },

      submitSMF_xhr(user, ae_activities) {
        /* new xhr SMF function 18th May 2021 */

        if (window.location.host == "localhost:8080") return null;

        // let ae_activities = ( source_channel == 'Spotify' ) ? {"actions":{"presave":75093}} : {"actions":{"formsubmission":75094}};

        // if( mailling_list ){
        //   ae_activities = ( source_channel == 'Spotify' ) ?  {"actions":{"presave":75093},"mailing_list_optins":{"a0S61000000Zh4lEAC":75095}} : {"actions":{"formsubmission":75094},"mailing_list_optins":{"a0S61000000Zh4lEAC":75095}};
        // }

        var xhr = new XMLHttpRequest();

        xhr.open("POST", "https://subs.sonymusicfans.com/submit");
        xhr.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded; charset=UTF-8"
        );
        var obj = {
          field_email_address: user.field_email_address,
          ...( user.ae_member_id && { ae_member_id: user.ae_member_id }),
          ...( user.field_dob && { field_dob: user.field_dob }),
          ...( user.first_name && { field_first_name: user.first_name }),
          ...( user.field_last_name && { field_last_name: user.field_last_name }),
          ...( user.field_country && { field_country_region: user.field_country }),
          ...( user.field_country_code && { field_country_code: user.field_country_code }),
          ...( user.source_channel && { source_channel: user.source_channel }), // can't use AE object as it's different format
          form: "133492",
          default_mailing_list: ( this.input.terms.newsletter ) ? "a0S61000000Zh4lEAC" : "", // empty on th first and this on the newsletter sign up!!!!
          ae: "053de84e36c3606d88ef6ba8548d6b5cb47c6df6953b4240a75f67aec45ca77b",
          ae_segment_id: "1216078",
          ae_brand_id: "3447201",
          ae_activities: ae_activities,
        };

        var pairs = [];

        for (var key in obj) {
          pairs.push(
            encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
          );
        }

        var data = pairs.join("&");

        xhr.send(data);

        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            console.log(xhr.response, e);
          } else {
            console.log(xhr.response, e);
          }
        };
      },
    },
  };
</script>
