import {
  // TextureLoader,
  BoxBufferGeometry,
  // MeshPhongMaterial,
  MeshBasicMaterial,
  Mesh,
  Group,
  // sRGBEncoding,
  PlaneBufferGeometry,
  // FaceColors,
  Color,
  DoubleSide,
  Vector3,
} from "three";

import { randomInt } from './functions.js';

class Maze {

  constructor( map, size = 32, offset = 0.002) { // offset to fix z-fighting

    const CONFIG = window.CONFIG || null;

    this.size = {
      w: (map[0].length * size) + (offset*map[0].length), // total width (including offset to fix z-fighting)
      h: (map.length * size/2) + (offset*map.length), // total height (including offset to fix z-fighting)
      x: size,
      y: size/2,
      z: size/2,
      o: offset,
    };

    this.origin = new Vector3( 0, 0, 0 );
    this.start = new Vector3( 0, 0, 0 );
    this.end = new Vector3( 0, 0, 0 );
    this.collectable = new Vector3( 0, 0, 0 );

    let wallMaterials, entranceMaterials, entranceSideMaterials, exitMaterials, exitSideMaterials, variations;

    if( CONFIG && CONFIG.debug ){

      wallMaterials = [
        new MeshBasicMaterial( { color: Math.random() * 0xffffff } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff } )
      ];

      entranceMaterials = [
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: false, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } )
      ];

      exitMaterials = [
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: false, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } ),
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide, visible: true, } )
      ];

      variations = [ 0,45,90,135,180,225,270,315,360 ];

      for (let index = 0; index < variations.length; index++) {

        const color = new Color( );
        color.convertSRGBToLinear();

        const variate = [
          wallMaterials[0].clone(),
          wallMaterials[1].clone(),
          wallMaterials[2].clone(),
          wallMaterials[3].clone(),
          wallMaterials[4].clone(),
          wallMaterials[5].clone(),
        ];

        variate.forEach(side => {
          side.color.set( color );
          side.color.setHSL( variations[index]/360, Math.ceil( Math.random() ), 0.5 );
        });

        variations[index] = variate;
      }

    }else{

      variations = [ 'blue', 'green', 'red', 'white', 'yellow' ]; 

      for (let index = 0; index < variations.length; index++) {

        const variate = [
          CONFIG.textures.find(obj => { return obj.name === variations[index]+'-door' }).material, // +X
          CONFIG.textures.find(obj => { return obj.name === variations[index]+'-end' }).material, // -X
          CONFIG.textures.find(obj => { return obj.name === variations[index]+'-top' }).material, // +Y (top)
          CONFIG.textures.find(obj => { return obj.name === variations[index]+'-top' }).material, // -Y (bottom)
          CONFIG.textures.find(obj => { return obj.name === variations[index]+'-side' }).material, // +Z
          CONFIG.textures.find(obj => { return obj.name === variations[index]+'-side' }).material, // -Z
        ]

        variations[index] = variate;
      }

      entranceMaterials = [
        CONFIG.textures.find(obj => { return obj.name === 'entrance-end' }).material, // +X
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide } ), // -X
        CONFIG.textures.find(obj => { return obj.name === 'entrance-top' }).material, // +Y (top)
        CONFIG.textures.find(obj => { return obj.name === 'entrance-top' }).material, // -Y (bottom)
        CONFIG.textures.find(obj => { return obj.name === 'entrance-side' }).material, // +Z
        CONFIG.textures.find(obj => { return obj.name === 'entrance-side' }).material, // -Z
      ];

      for (let index = 0; index < entranceMaterials.length; index++) {
        entranceMaterials[index].side = DoubleSide;
        entranceMaterials[index].visible = ( index == 1 ) ? false : true; // remember to leave a door open
      }

      entranceSideMaterials = [
        CONFIG.textures.find(obj => { return obj.name === 'entrance-side-left' }).material,
        CONFIG.textures.find(obj => { return obj.name === 'entrance-side-right' }).material
      ]


      exitMaterials = [
        CONFIG.textures.find(obj => { return obj.name === 'exit-end' }).material, // +X
        new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide } ), // -X
        CONFIG.textures.find(obj => { return obj.name === 'exit-top' }).material, // +Y (top)
        CONFIG.textures.find(obj => { return obj.name === 'exit-top' }).material, // -Y (bottom)
        CONFIG.textures.find(obj => { return obj.name === 'exit-side' }).material, // +Z
        CONFIG.textures.find(obj => { return obj.name === 'exit-side' }).material, // -Z
      ];

      for (let index = 0; index < exitMaterials.length; index++) {
        exitMaterials[index].side = DoubleSide;
        exitMaterials[index].visible = ( index == 1 ) ? false : true; // remember to leave a door open
      }

      exitSideMaterials = [
        CONFIG.textures.find(obj => { return obj.name === 'exit-side-left' }).material,
        CONFIG.textures.find(obj => { return obj.name === 'exit-side-right' }).material
      ]

    }

    const logoMaterials = [
      CONFIG.textures.find(obj => { return obj.name === 'logo-avalanche' }).material,
      CONFIG.textures.find(obj => { return obj.name === 'logo-emily' }).material,
      CONFIG.textures.find(obj => { return obj.name === 'logo-medicine' }).material,
      CONFIG.textures.find(obj => { return obj.name === 'logo-september' }).material,
      CONFIG.textures.find(obj => { return obj.name === 'logo-sos' }).material,
    ]

    const logoGeometry = new PlaneBufferGeometry( this.size.x, this.size.y );
    const logo = new Mesh( logoGeometry, logoMaterials[0] );
    const wallGeometry = new BoxBufferGeometry(this.size.x, this.size.y, this.size.z);
    const wall = new Mesh(wallGeometry, variations[0] );
    const entranceWall = new Mesh(wallGeometry, entranceMaterials );
    const exitWall = new Mesh(wallGeometry, exitMaterials );
    const position = new Vector3();

    // Map generation
    const walls = new Group();
    const logos = new Group();
    let w, l, y, ly, x, lx; 
    for (y = 0, ly = map.length; y < ly; y++) {
      for (x = 0, lx = map[x].length; x < lx; x++) {

        position.set( -this.size.w / 2 + this.size.x * x, this.size.y/2,  -this.size.h / 2 + this.size.z * y );

        /* prevent z-fighting... */
        position.x += this.size.o*x;
        position.z += this.size.o*y;
        position.y += this.size.o*y; // so it's not gitching with floor


        if (map[y][x] === 0) { // IS WALL TILE

          for (let index = 0; index <= randomInt(0, 3) ; index++) { /* WALL STACK */ 

            w = wall.clone()
            w.material = variations[ randomInt(0, variations.length-1)  ]; // VARIATIONS
            w.position.copy( position );
            w.position.y += index*this.size.y + (this.size.o*index); // extra to fix z-fighting
            walls.add(w);

            if( randomInt(0,9) >= 7 && !CONFIG.debug ){ // ~30% chance for Logo
              l = logo.clone();
              l.material = logoMaterials[ randomInt(0, logoMaterials.length-1) ]; // eval( 'logoMaterial' + randomInt(1,4)  );
              l.position.copy( position );
              l.position.y = w.position.y;
              l.position.z += this.size.z/2 + (this.size.o/2); // extra to fix z-fighting
              logos.add(l);
              // OTHER SIDE
              l = l.clone();
              l.rotation.x = Math.PI / 2 * 2;
              l.rotation.z = Math.PI / 2 * 2;
              l.position.z -= this.size.z + ((this.size.o/2)*2); // extra to fix z-fighting
              logos.add(l);
            }

          }
        }

        if (map[y][x] == 2 ) { // IS HOLLOW WALL

          w = entranceWall.clone()
          w.position.copy( position );
          walls.add(w);

          if( entranceSideMaterials ){

            l = logo.clone();
            l.material = entranceSideMaterials[ 1 ]; // eval( 'logoMaterial' + randomInt(1,4)  );
            l.position.copy( position );
            l.position.y = w.position.y;
            l.position.z += this.size.z/2 + (this.size.o/2); // extra to fix z-fighting
            logos.add(l);
            // OTHER SIDE
            l = l.clone();
            l.material = entranceSideMaterials[ 0 ]; // eval( 'logoMaterial' + randomInt(1,4)  );
            l.rotation.x = Math.PI / 2 * 2;
            l.rotation.z = Math.PI / 2 * 2;
            l.position.z -= this.size.z + ((this.size.o/2)*2); // extra to fix z-fighting
            logos.add(l);

          }

        }

        if (map[y][x] == 3) { // IS HOLLOW WALL

          w = exitWall.clone()
          w.position.copy( position );
          walls.add(w);

          if( exitSideMaterials ){

            l = logo.clone();
            l.material = exitSideMaterials[ 1 ]; // eval( 'logoMaterial' + randomInt(1,4)  );
            l.position.copy( position );
            l.position.y = w.position.y;
            l.position.z += this.size.z/2 + (this.size.o/2); // extra to fix z-fighting
            logos.add(l);
            // OTHER SIDE
            l = l.clone();
            l.material = exitSideMaterials[ 0 ]; // eval( 'logoMaterial' + randomInt(1,4)  );
            l.rotation.x = Math.PI / 2 * 2;
            l.rotation.z = Math.PI / 2 * 2;
            l.position.z -= this.size.z + ((this.size.o/2)*2); // extra to fix z-fighting
            logos.add(l);
          }

        }

        if (x == 0 && y == 0) this.origin.copy( position ); // ORIGIN
        if (map[y][x] === 2) this.start.copy( position ); // START TILE
        if (map[y][x] === 3) this.end.copy( position ); // END TILE
        if (map[y][x] === 4) this.collectable.copy( position ); // MAP TILE
        
      }
    }

    this.getWalls = function(){
      return walls;
    }

    this.getLogos = function(){
      return logos;
    }

  }
}

export { Maze };