import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
  router,
  render: h => h(App),
  created() {
    console.log('%c Modern English 🧭', 'color: white; background: black; padding: 4px 4px;'); //🧩 😕 🎲  🗺️ 
  },
}).$mount('#app')
