import {
  RepeatWrapping,
  LoadingManager,
  TextureLoader,
  MeshPhongMaterial,
  MeshBasicMaterial,
  AudioLoader,
  sRGBEncoding,
} from "three";

export function repeatTexture(texture, size) {
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  texture.repeat.x = size;
  texture.repeat.y = size;
  return texture;
}

export function loadMap(level, callback) {
  var ajax = new XMLHttpRequest();
  // ajax.open("GET", "../../maps/maze-" + level + ".json", true);
  ajax.open("GET", '/development_previews/3382/maps/maze-1.json' )
  ajax.onreadystatechange = function() {
      if (ajax.readyState == 4) {
        if(callback) callback( JSON.parse(ajax.responseText) );
      }
  }
  ajax.send(null);
}

export function randomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function loadSounds( sounds ) {
  return new Promise((resolve) => {
    let audio, element;
    sounds.forEach( sound => {
      audio = new Audio();
      element = document.createElement("source");
      element.type = "audio/mpeg";
      element.src  = sound.file;
      audio.loop = sound.loop;
      audio.volume = sound.volume;
      audio.appendChild(element);
      sound.audio = audio;
    });
    resolve(sounds); // TO DO: WAIT FOR ALL TO LOAD BEFORE RESOLVE
  });
}

export function loadImages( images ){
  return new Promise((resolve) => {
    images.forEach( image => {
      image.data = new Image();
      image.data.onload = ()=>{
        
      };
      image.data.src = image.file;
    });
    resolve(images); // TO DO: WAIT FOR ALL TO LOAD BEFORE RESOLVE
  });
}

export function _loadSounds( sounds ) {
  return new Promise((resolve, reject) => {
    // let textures = [];
    let onLoad = ()=>{
      resolve(sounds);
    };
    let onProgress = ()=>{};
    let onError = () =>{
      reject(false);
    };
    let manager = new LoadingManager(onLoad, onProgress, onError);
    sounds.forEach(sound => {
      let loader = new AudioLoader(manager);
      sound.audio = loader.load(sound.file);
      console.log( sound )
    });
  });
}

export function loadTextures( textures ) {
  return new Promise((resolve, reject) => {
    // let textures = [];
    let onLoad = ()=>{
      resolve(textures);
    };
    let onProgress = ()=>{};
    let onError = () =>{
      reject(false);
    };
    let manager = new LoadingManager(onLoad, onProgress, onError);
    textures.forEach(texture => {
      let loader = new TextureLoader(manager);
      texture.map = loader.load(texture.file);
      texture.map.encoding = sRGBEncoding;
      texture.material = ( texture.basic ) ? new MeshBasicMaterial({map: texture.map}) : new MeshPhongMaterial({map: texture.map});
      if( texture.transparent ) texture.material.transparent = true;
    });
  });
}

// export async function loadAssets( assets, callback ) {
//   const textures = await loadTextures(  assets.filter(obj => { return obj.type === 'texture' }) );
//   if(callback) callback( textures );
// }