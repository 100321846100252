<template>
  <div id="share">
    
    <div class="icons">
        <template v-if="open">
          <a 
            :class="share.type"
            v-for="share in shares"
            :key="share.type"
            :href="share.url" 
            target="_blank"
          >
            <span :class="[ 'icon-' + share.type ]"></span>
          </a>
          <a 
            :class="'link'"
            @click.prevent="copyurl();"
            target="_blank"
            v-if="supportsCB"
          >
            <transition name="appear">
              <div v-if="copied == true">COPIED</div>
            </transition>
          </a>
        </template>

      <h5 class="gold" v-on:click="toggleShare">SHARE</h5>

    </div>
    
  </div>
</template>
<script>
  export default {
    name: 'Share',
    data() {
      return {
        copied: false,
        open: false,
        shares: [ // TO DO: GET THESE DYNAMICALLY 
          {  type : 'facebook',  title: 'Facebook',  url: 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.origin },
          { type: 'twitter',   title: 'Twitter',   url: 'https://twitter.com/intent/tweet?text=' + window.location.origin }
        ],
        supportsCB:true,
      }
    },
    
    created () {
      if(navigator.clipboard) this.supportsCB = true;
    },

    methods: {
      toggleShare(){
        this.open = !this.open;
      },
      copyurl() {
        navigator.clipboard.writeText(window.location.origin);
        this.copied = true;
        setTimeout( () => {this.copied = false}, 2300 )
      }
    },
  }
</script>