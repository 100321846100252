import {
  BoxBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  AxesHelper,
  Group,
} from "three";

class Helpers {

  constructor( maze, map ) {

    const helpers = new Group();

    const cageGeometry = new BoxBufferGeometry( maze.size.w, maze.size.h, maze.size.h, map[0].length, map.length, map.length );
    const cageMaterial = new MeshBasicMaterial( { color: 0xff00ff, wireframe: true } );
    const cage = new Mesh( cageGeometry, cageMaterial );
    cage.position.x -= maze.size.x/2;
    cage.position.z -= maze.size.z/2;
    helpers.add(cage)

    const axesHelper = new AxesHelper( maze.size.x );
    axesHelper.position.y += 20;
    helpers.add( axesHelper );


    this.get = function(){
      return helpers;
    }

    return this;
  }
}
export { Helpers }