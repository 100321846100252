import {
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
  Fog,
  sRGBEncoding,
  Vector2,
  Vector3
} from "three";

const CONFIG = { // overwritten below
  useDeviceOrientation: true,
  useMouseControls: true,
  useTouchEvents: false,
}

if( window.isSecureContext === false || window.location.protocol == 'http:' ) CONFIG.useDeviceOrientation = false; // CAN ONLY DETECH ORIENTATION ON HTTPS
if( !matchMedia('(pointer:fine)').matches )  CONFIG.useMouseControls = false; // NO MOUSE FOUND
if( 'ontouchstart' in document.documentElement )  CONFIG.useTouchEvents = true; // USE TOUCH EVENT WHEN NO KEYBOARD

window.CONFIG = CONFIG; //lazy global!

import { MiniMap } from './inc/miniMap.js';
import { Maze } from './inc/maze.js';
import { Floor } from './inc/floor.js';
import { Controls } from './inc/controls.js';
import { Maps } from './inc/maps.js';
import { Sounds } from './inc/sounds.js';
import { Background } from "./inc/background.js";
import { PovBoard } from "./inc/povBoard.js";
import { Helpers } from "./inc/helpers.js";
import { Lights } from "./inc/lights.js";

class Game {

  constructor( assets, level = 0, debug = false, free = false ) {

    /* SETUP */
    CONFIG.sounds = assets.sounds;
    CONFIG.textures = assets.textures;
    CONFIG.images = assets.images;

    if( window.location.host == 'localhost:8080' ){
      CONFIG.debug = debug;
      CONFIG.free = free;
      console.info( 'CONFIG:', window.CONFIG );
    }

    let WIDTH =  window.innerWidth;
    let HEIGHT = window.innerHeight;

    let camera, scene, renderer, controls, miniMap, maze, maps, sounds, povBoard, lights, prevTime;
    const objects = {}; // ref to game objects
    prevTime = null;

    /* SCENE */ 
    scene = new Scene();
    // scene.background = new Color( 0x090B0B );
    if( !CONFIG.debug && !CONFIG.free ) scene.fog = new Fog( 0x090B0B, 0, 90 );

    /* CAMERA */
    const far = ( CONFIG.debug || CONFIG.free ) ? 750 : 500; 
    camera = new PerspectiveCamera( 75, WIDTH / HEIGHT, 1, far );
    camera.position.y = 5;
    scene.add(camera)

    /* BACKGROUND */
    if( !CONFIG.debug ){
      const background = new Background();
      scene.background = background.get();
    }
  
    /* CONTROLS */ 
    controls = new Controls(camera, CONFIG.useMouseControls, CONFIG.useTouchEvents, CONFIG.useDeviceOrientation);
    this.controls = controls.system; // so Vue can check if we if user "isPlaying" i.e. controls isLocked

    /* MAP LEVEL */
    maps = new Maps( level );
    const map = maps.get();

    /* FLOOR */ 
    const floor = new Floor( map );
    scene.add( floor );

    /* MAZE WALL */
    maze = new Maze( map );
    objects.walls = maze.getWalls();
    objects.logos = maze.getLogos();
    scene.add( objects.walls );
    scene.add( objects.logos  );
    
    /* START POSITION */
    camera.position.copy( maze.start ); 
    const direction = new Vector3();
    direction.copy( maze.start );
    direction.x -= 1;
    camera.lookAt( direction );

    /* LIGHTS */ 
    lights = new Lights( maze )
    scene.add( lights.get() );

    /* POV MINI MAP */ 
    miniMap = new MiniMap( map );
    const miniMapCanvas = miniMap.get();
    povBoard = new PovBoard( miniMapCanvas, maps.getLevel() );
    // povBoard.addCanvas(  miniMap.get() );
    const board = povBoard.get();
    board.name = 'board'; // SO WE CAN REMOVE IT LATER
    scene.add( board );
    board.position.copy( maze.collectable );
    board.position.y = 0.001;

    /* HELPERS */ 
    if( CONFIG.debug ){
      const helpers = new Helpers(maze,map);
      scene.add( helpers.get() );
    }

    /* AUDIO */
    sounds = new Sounds( assets.sounds );
    
    /* RENDER */
    renderer = new WebGLRenderer( { antialias: true, alpha: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( WIDTH, HEIGHT );
    renderer.gammaFactor = 2.2;
    renderer.outputEncoding = sRGBEncoding;
    renderer.physicallyCorrectLights = true; 

    /* RESIZE */ 
    window.addEventListener( 'resize', onWindowResize );
    function onWindowResize() {
      WIDTH =  window.innerWidth;
      HEIGHT = window.innerHeight;
      camera.aspect = WIDTH / HEIGHT;
      camera.updateProjectionMatrix();
      renderer.setSize( WIDTH, HEIGHT );
    }

    /* LIGHTS, CAMERA, ACTION 🎬 */ 

    let paused, ended, time, delta, pos;

    pos = new Vector2();

    let endTrigger = 60;

    function animate() {

      requestAnimationFrame( animate );

      time = performance.now();

      if( !paused && !ended ){

        sounds.play('background');

        if( controls.system.isLocked === false ){
          sounds.pauseAll();
          paused = true;
        }

        delta = ( time - prevTime ) / 1000;

        controls.update( delta, objects );

        if( controls.move.forward ){
          sounds.play('walking');
        }else{
          sounds.pause('walking');
        }

        if( controls.move.jump ) sounds.start( 'jump' );

        pos.x = Math.abs( Math.floor( ( (maze.origin.x + ( camera.position.x * -1 ) + (maze.size.x/2) ) / maze.size.x) ) );
        pos.y = Math.abs( Math.floor( ( (maze.origin.z + ( camera.position.z * -1 ) + (maze.size.y/2) ) / maze.size.y) ) );
        pos.tile = maps.getTile(pos); // can use this for hit collision as well!!?

        if( pos.tile == 0 && !CONFIG.debug ){ // COLLISION FAILSAFE: user managed to hacke inside a container, so reset the camera position 
          controls.reset();
        }

        miniMap.update( pos );
        povBoard.update( camera );

        if( pos.tile == 4 && !povBoard.inHand ){
          sounds.play('map');
          povBoard.pickup(camera,scene);
        }

        if( pos.tile == 5 ) sounds.play('wonder');
        if( pos.tile == 6 ) sounds.play('warmer');
        if( pos.tile == 7 ) sounds.play('dream');
        if( pos.tile == 8 ) sounds.play('recognise');
        
        if( pos.tile == 3 && endTrigger <= 0){ // FINISHED MAZE
          sounds.pauseAll();
          sounds.play('congratulations');
          ended = paused = true;
          controls.system.unlock();
        }else if( pos.tile == 3){
          endTrigger --;
        }

      }

      lights.update();
      renderer.render( scene, camera );
      prevTime = time;

    }

    this.toggleAudio = function(audio){
      sounds.toggleMuted(audio)
    }

    this.getEnded = function(){
      return ended;
    }

    this.getLevel = function(){
      return ( maps ) ? maps.getLevel() : null;
    }

    this.getMiniMap = function(){
      return ( miniMap ) ? miniMap.get() : null;
    }

    this.get = function(){
      return renderer.domElement;
    }

    this.start = function(){
      animate(); // we can start the loop, safe in the knowleadge it won't draw till controls are locked
    }

    this.resume = function(){

      if( !controls.system.connected ) controls.system.init();
      
      // console.log('RESUME');
      controls.system.lock();
      sounds.start('background', true);
      sounds.start('walking', true);
      sounds.start('map', true);
      sounds.start('wonder', true);
      sounds.start('warmer', true);
      sounds.start('dream', true);
      sounds.start('recognise', true);
      sounds.start('congratulations', true);

      setTimeout(() => {
        sounds.start('start');
        paused = false;
      }, 250);

    }

    this.pause = function(){
      controls.system.unlock();
    }

    return this;
  }

}

export { Game };