<template>
  <button v-on:click="$emit('click')" class="cta" :class="className" :disabled="disabled"><p><slot/></p></button>
</template>
<script>
  export default {
    name: 'Button',
    props: {
      disabled:{
        default: false,
        type: Boolean
      },
      className:{
        type: String
      },
    }
  }
</script>