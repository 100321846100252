import {
  Group,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  // DoubleSide,
  Vector3,
  CanvasTexture,
  LinearFilter,
  ClampToEdgeWrapping,
  // TextureLoader,
  MeshPhongMaterial,
  // sRGBEncoding,
} from "three";

class PovBoard {

  constructor( canvas, level ) {

    const CONFIG = window.CONFIG || null;

    this.origin = new Vector3();
    this.inHand = false; /* SO PLAYER CAN "PICK IT UP" */

    // const loader = new TextureLoader();
    const board = new Group(); /* board to follow the camera and put the map on */

    const boardGeo = new PlaneBufferGeometry( 3, 4 ); // 3, 3*1.41451612903
    
    //const clipboardMap = loader.load(require('@/assets/img/game/clipboard-back-desktop@2x.png')); 
    //clipboardMap.encoding = sRGBEncoding;
    // const boardMat = new MeshBasicMaterial( { color: Math.random() * 0xffffff, side : DoubleSide} );
    //const boardMat = new MeshPhongMaterial( { map: clipboardMap, transparent: true, } );

    let boardMat = ( CONFIG.debug ) ? new MeshBasicMaterial( { color: Math.random() * 0xffffff } ) : CONFIG.textures.find(obj => { return obj.name === 'clipboard-'+level }).material;

    const clipboard = new Mesh( boardGeo, boardMat );
    board.add(clipboard);

    let vector, input, percent, amountY, amountR, amountZ;
    vector = new Vector3( 0, 0, - 1 );
    amountR = -0.2;
    amountY = -5; // distance up the screen
    amountZ = -4; // distance away from camera

    board.rotation.x = amountR /* BETWEEN -0.4 and 0 */ 
    board.position.y = amountY; /* BETWEEN -20 and 0 */ 
    board.position.z = amountZ; /* BETWEEN -5 and -7.5 */ 

    /* canvas map texture to add to board */
    const canvasTexture = new CanvasTexture( canvas );
    canvasTexture.minFilter = LinearFilter;
    canvasTexture.wrapS = ClampToEdgeWrapping;
    canvasTexture.wrapT = ClampToEdgeWrapping;

    const canvasMaterial = new MeshPhongMaterial({
      map: canvasTexture,
    });

    const canvasGeometry = new PlaneBufferGeometry(2.5, 2.5);
    const canvasMesh = new Mesh(canvasGeometry, canvasMaterial);
    canvasMesh.position.z += 0.001;
    canvasMesh.position.y -= 0.5;
    board.add(canvasMesh);

    // const clipboardTopMap = loader.load(require('@/assets/img/game/clipboard-top-desktop@2x.png')); 
    // clipboardTopMap.encoding = sRGBEncoding;

    if(!CONFIG.debug ){
      const clipboardTop = clipboard.clone();
      clipboardTop.material = CONFIG.textures.find(obj => { return obj.name === 'clipboard-top' }).material;
      // clipboardTop.material = new MeshPhongMaterial( { map: clipboardTopMap, transparent: true, } );
      clipboardTop.position.z += 0.002;
      board.add(clipboardTop);
    }
    // var canvasText = document.createElement("canvas");
    // var ctx = canvasText.getContext('2d');
    // ctx.font = '12px halyard-display, sans-serif';
    // ctx.textAlign = 'center';
    // ctx.textBaseline = 'middle';
    // ctx.fillStyle = 'white';
    // ctx.fillText('Hello World', 100, 100);

    // const canvasTextTexture = new CanvasTexture( canvasText );
    // canvasTextTexture.minFilter = LinearFilter;
    // canvasTextTexture.wrapS = ClampToEdgeWrapping;
    // canvasTextTexture.wrapT = ClampToEdgeWrapping;

    // const canvasTextMaterial = new MeshBasicMaterial({
    //   map: canvasTextTexture,
    //   side: DoubleSide,
    // });

    // const canvasTextGeometry = new PlaneGeometry(2.5, 2.5);
    // const canvasTextMesh = new Mesh(canvasTextGeometry, canvasTextMaterial);
    // canvasTextMesh.position.z += 0.003;
    // canvasTextMesh.position.y += 0.5;
    // board.add(canvasTextMesh);


    this.origin.copy( board.position ); /* so we can place it in the hand later */ 

    if(!this.inHand){
      // board.position.set(0,0,0);
      board.rotation.x = - (Math.PI / 2);
      // board.position.y += 0.001
    }

    this.update = function( camera ) {

      if( !this.inHand ) return null;

      vector.set( 0, 0, - 1 ); // reset
      vector.applyQuaternion( camera.quaternion );

      input = Math.round( vector.y * 10) / 10;
      percent = ((input - -1) * 100) / ( 1 - -1 ); // ((input - min) * 100) / (max - min)
      amountR = ( (100-percent) * ( 0 - -0.4) / 100) + -0.4; // (percent * (max - min) / 100) + min
      amountY = ( (100-percent) * ( 0 - -10) / 100) + -10; // (percent * (max - min) / 100) + min
      amountZ = ( (100-percent) * ( -4 - -6) / 100) + -6; // (percent * (max - min) / 100) + min

      board.rotation.x = amountR ;// -0.35 to 0
      board.position.y = amountY ;// -10 to 0
      board.position.z = amountZ ;// -0.35 to 0

      canvasMesh.material.map.needsUpdate = true; 

    }

    this.pickup = function( camera, scene, name = 'board' ){
      const board = scene.getObjectByName( name );
      scene.remove( board );
      board.position.copy( this.origin );
      camera.add( board );
      this.inHand = true;
    }

    this.get = function() {
      return board;
    }

    return this;
  }
}
export { PovBoard }