import {
	Euler,
	EventDispatcher,
	MathUtils,
	Quaternion,
	Vector3
} from "three";

// const _vector = new Vector3();
const _zee = new Vector3( 0, 0, 1 );
const _euler = new Euler();
const _q0 = new Quaternion();
const _q1 = new Quaternion( - Math.sqrt( 0.5 ), 0, 0, Math.sqrt( 0.5 ) ); // - PI/2 around the x-axis

const _changeEvent = { type: 'change' };

class DeviceOrientationControls extends EventDispatcher {

	constructor( camera, domElement ) {

		super();



		this.isLocked = false;







		if ( window.isSecureContext === false ) {

			console.error( 'THREE.DeviceOrientationControls: DeviceOrientationEvent is only available in secure contexts (https)' );

		}

    if ( domElement === undefined ) {

			console.warn( 'THREE.PointerLockControls: The second parameter "domElement" is now mandatory.' );
			domElement = document.body;

		}
		
		const scope = this;

    this.domElement = domElement;
    
		// console.log(' DeviceOrientationControls isLocked', this.isLocked )

		const EPS = 0.000001;
		const lastQuaternion = new Quaternion();

		this.camera = camera;
		this.camera.rotation.reorder( 'YXZ' );

		this.enabled = true;

		this.deviceOrientation = {};
		this.screenOrientation = 0;

		this.alphaOffset = 0; // radians

		const onDeviceOrientationChangeEvent = function ( event ) {

			scope.deviceOrientation = event;

		};

		const onScreenOrientationChangeEvent = function () {

			scope.screenOrientation = window.orientation || 0;

		};

		// The angles alpha, beta and gamma form a set of intrinsic Tait-Bryan angles of type Z-X'-Y''

		const setObjectQuaternion = function ( quaternion, alpha, beta, gamma, orient ) {

			_euler.set( beta, alpha, - gamma, 'YXZ' ); // 'ZXY' for the device, but 'YXZ' for us

			quaternion.setFromEuler( _euler ); // orient the device

			quaternion.multiply( _q1 ); // camera looks out the back of the device, not the top

			quaternion.multiply( _q0.setFromAxisAngle( _zee, - orient ) ); // adjust for screen orientation

		};



		this.connect = function () {

			onScreenOrientationChangeEvent(); // run once on load

			// iOS 13+

			if ( window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function' ) {

				window.DeviceOrientationEvent.requestPermission().then( function ( response ) {

					if ( response == 'granted' ) {

						window.addEventListener( 'orientationchange', onScreenOrientationChangeEvent );
						window.addEventListener( 'deviceorientation', onDeviceOrientationChangeEvent );

					}

				} ).catch( function ( error ) {

					console.error( 'THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error );

				} );

			} else {

				window.addEventListener( 'orientationchange', onScreenOrientationChangeEvent );
				window.addEventListener( 'deviceorientation', onDeviceOrientationChangeEvent );

			}

			scope.enabled = true;

		};

		this.disconnect = function () {

			window.removeEventListener( 'orientationchange', onScreenOrientationChangeEvent );
			window.removeEventListener( 'deviceorientation', onDeviceOrientationChangeEvent );

			scope.enabled = false;

		};

		let device, alpha, beta, gamma, orient;

		this.update = function () {

			if ( scope.enabled === false || scope.isLocked === false ) return;

			device = scope.deviceOrientation;

			if ( device ) {

				alpha = device.alpha ? MathUtils.degToRad( device.alpha ) + scope.alphaOffset : 0; // Z

				beta = device.beta ? MathUtils.degToRad( device.beta ) : 0; // X

				gamma = device.gamma ? MathUtils.degToRad( device.gamma ) : 0; // Y

				orient = scope.screenOrientation ? MathUtils.degToRad( scope.screenOrientation ) : 0; // O

				setObjectQuaternion( scope.camera.quaternion, alpha, beta, gamma, orient );

				if ( 8 * ( 1 - lastQuaternion.dot( scope.camera.quaternion ) ) > EPS ) {

					lastQuaternion.copy( scope.camera.quaternion );
					scope.dispatchEvent( _changeEvent );

				}

			}

		};

		this.dispose = function () {

			scope.disconnect();

		};

    this.getObject = function () { // retaining this method for backward compatibility

			return this.camera;

		};

		this.getDirection = function () {

			const direction = new Vector3( 0, 0, - 1 );

			return function ( v ) {

				return v.copy( direction ).applyQuaternion( this.camera.quaternion );

			};

		}();

    this.moveForward = function ( distance ) {

			// move forward parallel to the xz-plane
			// assumes camera.up is y-up

			// TO DO: don't assume that camera.up is y-up in case tablet/phone has been rotated

			_zee.setFromMatrixColumn( this.camera.matrix, 0 );
			_zee.crossVectors( this.camera.up, _zee );
			this.camera.position.addScaledVector( _zee, distance );

		};

    
		this.moveRight = function ( distance ) {
			_zee.setFromMatrixColumn( this.camera.matrix, 0 );
			this.camera.position.addScaledVector( _zee, distance );

		};

    this.lock = function () {
			this.isLocked = true;

		};

    this.unlock = function () {
			this.isLocked = false;
		};

		this.connected = false;

		this.init = function(){ // do this only when user clicks
			this.conneceted = true;
			this.connect();
		};

	}

}

export { DeviceOrientationControls };