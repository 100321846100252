import {
  Vector3,
  Color,
  // TextureLoader,
  PlaneBufferGeometry,
  Float32BufferAttribute,
  // MeshLambertMaterial,
  MeshBasicMaterial,
  Mesh,
  // sRGBEncoding
} from "three";

import { repeatTexture } from './functions.js';

class Floor {

  constructor( map, size = 32 ) {

    const CONFIG = window.CONFIG || null;
    
    this.size = {
      w: map[0].length * size, // total width
      h: map.length * size/2, // total height
      x: size,
      y: size/2,
      z: size/2,
    };

    let floorGeometry = new PlaneBufferGeometry( this.size.w, this.size.h, this.size.y, this.size.x ); // MIN FLOOR WE NEED
    floorGeometry.rotateX( - Math.PI / 2 );

    let position;
    const flat = true
    if(!flat){
      position = floorGeometry.attributes.position;
      const vertex = new Vector3();
      for ( let i = 0, l = position.count; i < l; i ++ ) {
        vertex.fromBufferAttribute( position, i );
        vertex.x += Math.random() * 20 - 10;
        vertex.y += Math.random() * 2;
        vertex.z += Math.random() * 20 - 10;
        position.setXYZ( i, vertex.x, vertex.y, vertex.z );
      }
    }

    let floorMaterial;

    if( CONFIG && CONFIG.debug ){
      floorGeometry = floorGeometry.toNonIndexed(); // ensure each face has unique vertices
      position = floorGeometry.attributes.position;
      const colorsFloor = [];
      const color = new Color();
      color.convertSRGBToLinear();
      for ( let i = 0, l = position.count; i < l; i ++ ) {
        color.setHSL( Math.random() * 0.3 + 0.5, 0.75, Math.random() * 0.25 + 0.75 );
        colorsFloor.push( color.r, color.g, color.b );
      }
      floorGeometry.setAttribute( 'color', new Float32BufferAttribute( colorsFloor, 3 ) );
      floorMaterial = new MeshBasicMaterial( { vertexColors: true } );
    }else {
      floorMaterial = CONFIG.textures.find(obj => { return obj.name === 'ground' }).material
    }

    const floor = new Mesh( floorGeometry, floorMaterial );
    floor.position.x -= this.size.x/2;
    floor.position.z -= this.size.z/2;
    if( CONFIG && !CONFIG.debug ) repeatTexture(floor.material.map, 16);
    
    return floor;
  }
}

export { Floor };