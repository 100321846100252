<template>
  <button v-on:click="onClick" class="album">
    <div>
      <img alt="JA Album" src="~@/assets/img/other/album-cover@4x.jpg"/>
    </div>
    <div>
      <h5>Album available everywhere</h5>
      <h5 class="gold">Listen now <span>&#11157;</span></h5>
    </div>
  </button>
</template>
<script>
  export default {
    name: 'Album',
    methods: {
      onClick: function( ) {
        window.open('http://jamesarthur.lnk.to/itllallmakesenseintheend', '_blank');
      }
    }
  }
</script>