<template>
  <section id="outro">

    <div class="left landscape">
      <AlbumButton />
    </div>

    <div class="right">
      
      <div>
        <br>
        <Logo/>
        <br>
        <h3 class="gold">CONGRATULATIONS</h3>
        <br>
        <p>You navigated your way through the maze to It'll All Make Sense In The End. Watch a special video message from James.</p>
        <br>
        <div class="video">
          <!-- <iframe width="640" height="360" src="https://www.youtube.com/embed/eWxg0hcMNGc" frameborder="0"></iframe> -->
          <!-- <iframe width="640" height="360" src="https://www.youtube.com/embed/U7tSV63hGbI" frameborder="0"></iframe> -->
          <iframe width="640" height="360" src="https://www.youtube.com/embed/2H2Zrj0J5pw" frameborder="0"></iframe>
        </div>
        <br>
      </div>

      <Authentication v-if="!authenticated"/>

      <div v-if="authenticated">
        <h3 class="gold">ENTRY SUCCESSFUL!</h3>
        <br>
        <p>We'll be in touch if you win! </p>
        <br>
        <p>Until then, why not play again, there are different mazes to solve...</p>
        <br>
        <Button v-on:click="onClick">Play again</Button>
      </div>


    </div>

    <AlbumButton class="portrait"/>
    
  </section>
</template>
<script>
  import Logo from './Logo.vue';
  import AlbumButton from './AlbumButton.vue';
  import Button from './Button.vue';
  import Authentication from './Authentication.vue';
  
  export default {
    name: 'Outro',
    components: {
      Logo,
      AlbumButton,
      Button,
      Authentication,
    },
    data() {
      return {
        authenticated: true,
      };
    },
    mounted: function () {
      const auth = localStorage.getItem("Authentication");
      if (auth && JSON.parse(auth).complete == true) return null;
      this.authenticated = false;
      // this.playEndSound();
    },
    methods: {

      // playEndSound(){
      //   const audio = new Audio();
      //   const element = document.createElement("source");
      //   element.type = "audio/mpeg";
      //   element.src  = require("@/assets/media/06-congratulations-does-it-all-make-sense-now.mp3");
      //   audio.volume = 0.25;
      //   audio.appendChild(element);
      //   audio.play();
      // },

      onClick: function( ) {
        this.$router.push({ name: 'maze' });
      }
      
    }
  }
</script>