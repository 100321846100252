import Vue from 'vue'
import Router from 'vue-router'
import Intro from '../components/Intro.vue'
import Maze from '../components/Maze.vue'
import Outro from '../components/Outro.vue'

Vue.use(Router);

export default new Router({
	// mode: 'history',
	mode: 'hash',
	routes: [
		// { path: '/', redirect: '/intro' },
		{
			path: '/',
			name: 'intro',
			component: Intro
		},
		{
			path: '/maze/:level?/:debug?/:free?',
			name: 'maze',
			component: Maze,
		},
		{
			path: '/iamsite',
			name: 'outro',
			component: Outro
		},
		{
			path :'*',
			redirect: '/' 
		}
	]
})
